import { useEffect } from "react";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { Helmet } from "react-helmet";
import DataSecurityImg from "../img/bg-data-security.svg";
import "./DataSecurity.css";
import CheckBoxImg from "../img/check.svg";

const DataSecurity = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount/update
  }, []);
  return (
    <div>
      <>
        <div>
          <Helmet>
            <title>ResearchON</title>
            <meta
              name="title"
              content="ResearchON | Start your research Journey"
            />
            <meta
              name="keywords"
              content="ResearchON, Cognidoc Healthtech Private Limited, Dockare, Orbit, Healthcare"
            />
            <meta
              name="description"
              content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
            />
            <meta
              property="og:title"
              content="ResearchON | Start your research Journey"
            />
            <meta property="og:url" content="https://www.researchon.ai" />
            <meta
              property="og:description"
              content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
            />
            <meta
              property="og:image"
              content="https://www.researchon.ai/assets/img/researchon-logo.png"
            />
            <meta property="og:type" content="Healthtech" />
          </Helmet>
        </div>
        {/* ======= Header ======= */}
        <header id="header" className="fixed-top ">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="logo">
              <h1>
                <Link to="/">
                  <img
                    src="../assets/img/logo.png"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              </h1>
              {/* Uncomment below if you prefer to use an image logo */}
              {/* <a href="index.html"><img src="../assets/img-svg/logo.png" alt="" class="img-fluid"></a>*/}
            </div>
            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a
                    className="getstarted scrollto"
                    href="https://myportal.researchon.ai"
                    target="_blank"
                  >
                    Access MyPortal
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>
            {/* .navbar */}
          </div>
        </header>
        {/* End Header */}
        <main id="main">
          {/* ======= Breadcrumbs Section ======= */}
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2>Data Security</h2>
                <ol>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Data Security</li>
                </ol>
              </div>
            </div>
          </section>
          {/* End Breadcrumbs Section */}
          <section
            className="inner-page"
            style={{ padding: "0", fontFamily: "Inter" }}
          >
            {/* Ruchith section start */}
            <div className="container">
              <div className="row">
                <div
                  className="col-md-8"
                  // style={{ width: "100%", paddingTop: "7vh" }}
                >
                  <div className=" bg-container-main">
                    <div className="mb-4 bg-img-head">
                      Ensuring{" "}
                      <span style={{ color: "#ff6600" }}>Data Security</span>:
                      Our Commitment to Protecting Your Information
                    </div>
                    <p
                      className="mb-4 bg-container-subdiv"
                      style={{ color: "#2f2f2f" }}
                    >
                      At{" "}
                      <span style={{ fontWeight: "bolder" }}>ResearchON</span>,
                      safeguarding your data is our utmost priority. We employ
                      cutting-edge encryption protocols to ensure that all user
                      information, whether personal or transactional, remains
                      secure and confidential. Our robust data security measures
                      encompass multiple layers of protection, including secure
                      socket layer (SSL) technology for data transmission,
                      stringent access controls, and continuous monitoring for
                      any suspicious activities. Furthermore, we adhere to
                      industry best practices and comply with relevant data
                      protection regulations to uphold the trust and privacy of
                      our users.
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-4"
                  // style={{ width: "100%", paddingTop: "7vh" }}
                >
                  <img
                    src={"../assets/img/data-security-bg.jpg"}
                    style={{ marginTop: "10vh" }}
                  />
                </div>

                <div className="col-md-12" style={{ marginTop: "4vh" }}>
                  <p>
                    <img src={"../assets/img/checkbox.png"} alt="" /> &nbsp;
                    Your details and uploaded files are securely hosted within
                    SOC1-certified data centers, ensuring the highest standards
                    of protection.{" "}
                  </p>

                  <p>
                    <img src={"../assets/img/checkbox.png"} alt="" /> &nbsp; We
                    implement robust access controls on our servers to safeguard
                    your data effectively.{" "}
                  </p>
                  <p>
                    <img src={"../assets/img/checkbox.png"} alt="" /> &nbsp; We
                    use firewalls to block unauthorized access and uphold the
                    integrity of our private network.{" "}
                  </p>
                  <p>
                    <img src={"../assets/img/checkbox.png"} alt="" /> &nbsp;
                    Your data is managed on Amazon Web Services (AWS) – renowned
                    for its reliability and strict access management.{" "}
                  </p>
                  <p>
                    <img src={"../assets/img/checkbox.png"} alt="" /> &nbsp; We
                    are committed to safeguarding your health information,
                    ResearchON fully complies with HIPAA standards.{" "}
                  </p>
                </div>

                {/* Add more columns or content as needed */}
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#f2f5fa",
                padding: "40px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "5vh",
              }}
            >
              <img
                src="../assets/img/hippa.jpeg"
                style={{
                  width: "200px",
                  border: "1px solid #0D487B",
                  borderRadius: "4px",
                }}
                alt=""
              />
              {/* <img
                src="../assets/img/aws.webp"
                style={{
                  width: "200px",
                  border: "1px solid #0D487B",
                  borderRadius: "4px",
                  marginLeft: "40px",
                }}
                alt=""
              /> */}
            </div>

            {/* Ruchith section end */}

            {/* Ramesh section start */}
            <div
              className="container"
              style={{ marginTop: "10vh", marginBottom: "10vh" }}
            >
              <div className="row">
                <div className="col-md-8 offset-md-2 col-sm-12 col-xs-12 text-center col-lg-8 offset-lg-2">
                  <h3
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "Inter",
                    }}
                  >
                    The practices that we follow while protecting your data 
                  </h3>
                  {/* <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Inter",
                      color: "#2F2F2F",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p> */}
                </div>
              </div>
              <div className="row">
                {/* First Item */}
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="p-4 mx-1 my-3"
                    style={{
                      border: "1px solid #dadada",
                      borderRadius: 7,
                      minHeight: 300,
                      boxShadow: "0px 3px 8px 1px #ededed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          border: "1px solid #EE5E23",
                          borderRadius: "50%",
                          padding: 9,
                        }}
                      >
                        <img
                          src="../assets/img/security.png"
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          margin: 0,
                          fontFamily: "Inter",
                        }}
                      >
                        Security Measures 
                      </p>
                    </div>
                    <p
                      style={{
                        color: "#2F2F2F",
                        fontFamily: "Inter",
                        marginTop: 20,
                      }}
                    >
                      We use latest Linux servers which are regularly updated to
                      keep them safe and secure. When you visit ResearchON
                      application (web/mobile), your connection is protected
                      with the highest level of security (TLS 1.2), ensuring
                      your requests are safe from potential attacks. In
                      addition, our SSL certificates, signed with SHA256,
                      provide an extra layer of protection with strong
                      encryption.{" "}
                    </p>
                  </div>
                </div>
                {/* Second Item */}
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="p-4 mx-1 my-3"
                    style={{
                      border: "1px solid #dadada",
                      borderRadius: 7,
                      minHeight: 300,
                      boxShadow: "0px 3px 8px 1px #ededed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          border: "1px solid #EE5E23",
                          borderRadius: "50%",
                          padding: 9,
                        }}
                      >
                        <img
                          src="../assets/img/cloud-computing.png"
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          margin: 0,
                          fontFamily: "Inter",
                        }}
                      >
                        Secure Cloud Infrastructure
                      </p>
                    </div>
                    <p
                      style={{
                        color: "#2F2F2F",
                        fontFamily: "Inter",
                        marginTop: 20,
                      }}
                    >
                      Our servers are in AWS Virtual Private Cloud, with
                      controls to stop unauthorized access. We use different
                      Virtual Private Clouds for different environments to keep
                      data safe and protected. In addition, we have automatic
                      backups to prevent any data loss.{" "}
                    </p>
                  </div>
                </div>
                {/* Third Item */}
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="p-4 mx-1 my-3"
                    style={{
                      border: "1px solid #dadada",
                      borderRadius: 7,
                      minHeight: 300,
                      boxShadow: "0px 3px 8px 1px #ededed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          border: "1px solid #EE5E23",
                          borderRadius: "50%",
                          padding: 9,
                        }}
                      >
                        <img
                          src="../assets/img/data-security.png"
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          margin: 0,
                          fontFamily: "Inter",
                        }}
                      >
                        Your Data Security Assurance
                      </p>
                    </div>
                    <p
                      style={{
                        color: "#2F2F2F",
                        fontFamily: "Inter",
                        marginTop: 20,
                      }}
                    >
                      With regards to data handling, any user generated
                      information is stored securely in AWS (RDS, S3 and Dynamo
                      DB). The data is encrypted using a KMS key for added
                      protection.{" "}
                    </p>
                  </div>
                </div>
                {/* Fourth Item */}
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="p-4 mx-1 my-3"
                    style={{
                      border: "1px solid #dadada",
                      borderRadius: 7,
                      minHeight: 300,
                      boxShadow: "0px 3px 8px 1px #ededed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          border: "1px solid #EE5E23",
                          borderRadius: "50%",
                          padding: 9,
                        }}
                      >
                        <img
                          src="../assets/img/confidential.png"
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          margin: 0,
                          fontFamily: "Inter",
                        }}
                      >
                        Our Commitment to Confidentiality
                      </p>
                    </div>
                    <p
                      style={{
                        color: "#2F2F2F",
                        fontFamily: "Inter",
                        marginTop: 20,
                      }}
                    >
                      At ResearchON, your privacy matters. Our team undergo
                      regular training on how to keep your information safe and
                      signs agreements to do so. We have strict rules about who
                      can access your data. When your data is in the transit,
                      it's always protected with HTTPS for extra security. We
                      also make sure to keep your data partitioned and stored
                      separately for added protection.{" "}
                    </p>
                  </div>
                </div>
                {/* Fifth Item */}
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="p-4 mx-1 my-3"
                    style={{
                      border: "1px solid #dadada",
                      borderRadius: 7,
                      minHeight: 300,
                      boxShadow: "0px 3px 8px 1px #ededed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          border: "1px solid #EE5E23",
                          borderRadius: "50%",
                          padding: 9,
                        }}
                      >
                        <img
                          src="../assets/img/data-transit.png"
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          margin: 0,
                          fontFamily: "Inter",
                        }}
                      >
                        Data Transit Security
                      </p>
                    </div>
                    <p
                      style={{
                        color: "#2F2F2F",
                        fontFamily: "Inter",
                        marginTop: 20,
                      }}
                    >
                      Your information is always safe with us, even when it is
                      in transit. When you send or receive data through
                      ResearchON, it's encrypted with strong 256-bit encryption.
                      Our application only uses TLS/SSL for secure
                      communication.{" "}
                    </p>
                  </div>
                </div>
                {/* Sixth Item */}
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div
                    className="p-4 mx-1 my-3"
                    style={{
                      border: "1px solid #dadada",
                      borderRadius: 7,
                      minHeight: 300,
                      boxShadow: "0px 3px 8px 1px #ededed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: 20,
                      }}
                    >
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          border: "1px solid #EE5E23",
                          borderRadius: "50%",
                          padding: 9,
                        }}
                      >
                        <img
                          src="../assets/img/data-safety.png"
                          alt=""
                          style={{ width: 30 }}
                        />
                      </div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          margin: 0,
                          fontFamily: "Inter",
                        }}
                      >
                        Ensuring Your Data's Safety
                      </p>
                    </div>
                    <p
                      style={{
                        color: "#2F2F2F",
                        fontFamily: "Inter",
                        marginTop: 20,
                      }}
                    >
                      We use a secure database to store your data offline,
                      keeping it safe with encryption. Our APIs are protected
                      with access tokens, ensuring only authorized users can
                      access our services. Your chat messages are stored
                      securely with encryption, guaranteeing your privacy and
                      keeping your conversations confidential.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Ramesh section end */}
          </section>

          <section className="faq section-bg" id="faq">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">Frequently Asked Questions</h1>
              </div>
              <div className="accordion-list">
                <ul>
                  <li className="aos-init aos-animate" data-aos="fade-up">
                    <a
                      className="collapse"
                      data-bs-target="#accordion-list-1"
                      data-bs-toggle="collapse"
                    >
                      1. How do you keep my research data safe?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse show"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-1"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        We make sure your research and data is safe. We focus on
                        you, the researcher, and your needs. Your data is
                        important, so we use strong safeguards to protect them
                        physically, procedurally, and technically. Your data is
                        processed, protected, and stored securely. We keep it in
                        safe certified data centers, with firewalls and access
                        restrictions on our servers. We use up-to-date, patched
                        Linux systems and secure platforms like Amazon Cloud
                        Storage. Our application is extra secure, with the
                        strongest HTTPS security and SSL certificates. We also
                        control who can access your data, and everyone who works
                        with us signs a strict confidentiality agreement.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-3"
                      data-bs-toggle="collapse"
                    >
                      2. What happens to the data that is stored on ResearchON
                      platform?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-3"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        At ResearchON, we keep your data as long as your
                        research is on our platform. We hold onto your personal
                        info until you tell us to delete it. There exist SOP for
                        deleting your data. If you wish to delete the data, you
                        can write to us or send request from the ResearchON
                        mobile App.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-4"
                      data-bs-toggle="collapse"
                    >
                      3. Will ResearchON view my research data?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-4"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        No, we do not view or publish your research. Our goal is
                        to offer researchers a helpful tool for collecting and
                        analysing the data, making the process faster and more
                        efficient. We respect that your research belongs to you
                        alone. Be assured, we do not publish your data or use
                        your data in any way. Additionally, your data is not
                        visible to anyone except you.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-5"
                      data-bs-toggle="collapse"
                    >
                      4. Does ResearchON utilize any of my personal information?
                      or Will anyone else have access to my research data on
                      your platform?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-5"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        Absolutely not! ResearchON does not utilize your data or
                        content whatsoever. We ensure full confidentiality of
                        your information, including personal details and
                        research data processed on our platform. Only registered
                        researchers on ResearchON can access their own research
                        content and any team members they've added. Each team
                        member has access based on their assigned role, and
                        we've implemented thorough access controls for added
                        security.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-6"
                      data-bs-toggle="collapse"
                    >
                      5. Is my data visible to any third-party services or
                      plagiarism detection tools?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-6"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        Absolutely not! We ensure full confidentiality of your
                        information. Only registered researchers on ResearchON
                        can access their own research content and any team
                        members they've added.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-7"
                      data-bs-toggle="collapse"
                    >
                      6. How do you handle data breaches or security incidents
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-7"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        In the event of a data breach or security incident,
                        Platform takes immediate action to contain the breach
                        and mitigate any potential damage. Our team follows a
                        predefined incident response plan that includes
                        notifying relevant stakeholders, investigating the root
                        cause of the breach, and implementing necessary measures
                        to prevent future incidents. We prioritize transparency
                        and will communicate openly with affected users about
                        the breach, providing guidance on steps they can take to
                        protect their data.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-8"
                      data-bs-toggle="collapse"
                    >
                      7. How can I delete my uploaded data?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-8"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        You can write to us at{" "}
                        <a
                          href="mailto:team@researchon.ai"
                          style={{ display: "inline" }}
                        >
                          team@researchon.ai
                        </a>{" "}
                        if you want to delete your uploaded file or any data.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h4>Unleash Your Research Potential with ResearchON</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <img
                    src="../assets/img/logo.png"
                    alt=""
                    className="img-fluid"
                    style={{ width: 160 }}
                  />
                  <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                    HSR Layout <br />
                    Bangalore - 560102
                    <br />
                    India <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+91 89717 02797"> +91 89717 02797 </a>
                    <br />
                    <strong>Email:</strong>{" "}
                    <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                    <br />
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/terms-and-conditions/">Terms of service</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/privacy-policy/">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://myportal.researchon.ai" target="_blank">
                        MyPortal
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/download-app/">Download App</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/data-security/">Data Secuirty</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Social Networks</h4>
                  <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                    Please follow us on our social media platforms
                  </p>
                  <div className="social-links mt-3">
                    <a href="#" className="instagram">
                      <i className="bx bxl-instagram" />
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
            <div className="copyright">
              COGNIDOC HEALTHTECH PRIVATE LIMITED. © 2024. All Rights Reserved
            </div>
            <div className="credits"></div>
          </div>
        </footer>
        {/* End Footer */}
      </>
    </div>
  );
};

export default DataSecurity;
