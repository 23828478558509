import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import DownloadApp from "./components/DownloadApp";
import ThanksForDownloadingApp from "./components/ThanksForDownloadingApp";
import DeleteAccount from "./components/DeleteAccount";
import ShareApp from "./components/ShareApp";
import DataSecurity from "./components/DataSecurity";
import ReactGA from "react-ga4";

function App() {
  if (window.location.pathname !== "/delete-account") {
    // Google Analytics
    const googleCode = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;
    ReactGA.initialize(googleCode);

    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "ResearchON - Website",
    });
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/download-app" element={<DownloadApp />} />
          <Route
            path="/download-our-app"
            element={<ThanksForDownloadingApp />}
          />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/share-app/:key" element={<ShareApp />} />
          <Route path="/data-security" element={<DataSecurity />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
