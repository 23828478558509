import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const DeleteAccount = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount/update
  }, []);
  return (
    <>
      <div>
        <Helmet>
          <title>ResearchON</title>
          <meta name="title" content="ResearchON" />
          <meta
            name="keywords"
            content="ResearchON, Cognidoc Healthtech Private Limited, Dockare, Orbit, Healthcare"
          />
          <meta
            name="description"
            content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
          />
          <meta property="og:title" content="ResearchON" />
          <meta property="og:url" content="https://www.researchon.ai" />
          <meta
            property="og:description"
            content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
          />
          <meta
            property="og:image"
            content="https://www.researchon.ai/assets/img/researchon-logo.png"
          />
          <meta property="og:type" content="Healthtech" />
        </Helmet>
      </div>

      {/* ======= Header ======= */}
      <header id="header" className="fixed-top ">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
              <Link to="/">
                <img
                  src="../assets/img/logo.png"
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </h1>
            {/* Uncomment below if you prefer to use an image logo */}
            {/* <a href="index.html"><img src="../assets/img/logo.png" alt="" class="img-fluid"></a>*/}
          </div>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a
                  className="getstarted scrollto"
                  href="https://myportal.researchon.ai"
                  target="_blank"
                >
                  Access MyPortal
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}

      <main id="main">
        {/* ======= Breadcrumbs Section ======= */}
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Delete Account</h2>
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Delete Account</li>
              </ol>
            </div>
          </div>
        </section>
        {/* End Breadcrumbs Section */}
        <section className="inner-page">
          <div className="container">
            <h3>
              You have the option to ask for your account's deletion directly
              within the app.
            </h3>
            <p className="lead">
              Follow the steps to request account deletion:
            </p>
            <ol>
              <li>
                Open your ResearchON mobile application. (If you are not logged
                in, login to the app)
              </li>
              <li>Navigate to the hamburger menu</li>
              <li>Go to settings</li>
              <li>Click on delete my account</li>
              <li>State reason for account deletion</li>
              <li>Click on request to delete my account</li>
            </ol>
            <p className="lead">
              If you uninstalled the app, write to us via{" "}
              <a href="mailto:team@researchon.ai">
                <strong>team@researchon.ai</strong>
              </a>
            </p>
            <p className="lead">Or</p>
            <p className="lead">
              Download the app from the below link and follow the above process.
            </p>
            <p className="lead">
              <a
                target="_blank"
                href="https://researchon.ai/download-our-app/"
                rel="noopener noreferrer"
              >
                <strong>Download Our App</strong>
              </a>
            </p>
          </div>
        </section>
      </main>
      {/* End #main */}

      {/* ======= Footer ======= */}
      <footer id="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Unleash Your Research Potential with ResearchON</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <img
                  src="../assets/img/logo.png"
                  alt=""
                  className="img-fluid"
                  style={{ width: 160 }}
                />
                <p>
                  HSR Layout <br />
                  Bangalore - 560102
                  <br />
                  India <br />
                  <br />
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+91 89717 02797"> +91 89717 02797 </a>
                  <br />
                  <strong>Email:</strong>{" "}
                  <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                  <br />
                </p>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/terms-and-conditions/">Terms of service</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/privacy-policy/">Privacy policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="https://myportal.researchon.ai" target="_blank">
                      MyPortal
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/download-app/">Download App</Link>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link to="/data-security/">Data Secuirty</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p>Please follow us on our social media platforms</p>
                <div className="social-links mt-3">
                  <a href="#" className="instagram">
                    <i className="bx bxl-instagram" />
                  </a>
                  <a href="#" className="linkedin">
                    <i className="bx bxl-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <div className="copyright">
            COGNIDOC HEALTHTECH PRIVATE LIMITED. © 2024. All Rights Reserved
          </div>
          <div className="credits"></div>
        </div>
      </footer>
      {/* End Footer */}
    </>
  );
};

export default DeleteAccount;
