import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount/update
  }, []);
  return (
    <div>
      <>
        <div>
          <Helmet>
            <title>ResearchON</title>
            <meta name="title" content="ResearchON" />
            <meta
              name="keywords"
              content="ResearchON, Cognidoc Healthtech Private Limited, Dockare, Orbit, Healthcare"
            />
            <meta
              name="description"
              content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
            />
            <meta property="og:title" content="ResearchON" />
            <meta property="og:url" content="https://www.researchon.ai" />
            <meta
              property="og:description"
              content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
            />
            <meta
              property="og:image"
              content="https://www.researchon.ai/assets/img/researchon-logo.png"
            />
            <meta property="og:type" content="Healthtech" />
          </Helmet>
        </div>
        {/* ======= Header ======= */}
        <header id="header" className="fixed-top ">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="logo">
              <h1>
                <Link to="/">
                  <img
                    src="../assets/img/logo.png"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              </h1>
              {/* Uncomment below if you prefer to use an image logo */}
              {/* <a href="index.html"><img src="../assets/img/logo.png" alt="" class="img-fluid"></a>*/}
            </div>
            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a
                    className="getstarted scrollto"
                    href="https://myportal.researchon.ai"
                    target="_blank"
                  >
                    Access MyPortal
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>
            {/* .navbar */}
          </div>
        </header>
        {/* End Header */}
        <main id="main">
          {/* ======= Breadcrumbs Section ======= */}
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2>Terms &amp; Conditions</h2>
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Terms &amp; Conditions</li>
                </ol>
              </div>
            </div>
          </section>
          {/* End Breadcrumbs Section */}
          <section className="inner-page">
            <div className="container">
              <div className="WordSection1">
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "normal",
                  }}
                >
                  <span lang="EN-IN">&nbsp;</span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Cognidoc Healthtech Private Limited ("
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        ResearchON
                      </span>
                    </b>
                    " / "
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        we
                      </span>
                    </b>
                    " / "
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        us
                      </span>
                    </b>
                    ", including its affiliates/group companies), has designed
                    and developed a mobile application/website based console ("
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Portal
                      </span>
                    </b>
                    "), that is{" "}
                  </span>
                  <span
                    style={{
                      color: "black",
                    }}
                  >
                    a mobile application and website-based console designed to
                    run on personal computers, smartphones and tablets and other
                    mobile computing devices, which facilitates{" "}
                    <i>inter alia</i>, - (i) Create multiple studies and
                    communicate and collaborate between health care
                    professionals, researchers, data collectors, graduates and
                    postgraduate students of the institute and allied health
                    professionals and other permitted staff of the institute,
                    and individual users, collectively, and (ii) enable the
                    researchers and their team members to create, collect and
                    communicate. Researchers and their respective teams which
                    include Users and student groups to communicate and perform
                    collaborative research and audit by building electronic Data
                    collection forms
                  </span>
                  <span
                    style={{
                      color: "#333333",
                    }}
                  >
                    {" "}
                    <span lang="EN-IN">
                      (hereinafter jointly referred to as the "
                      <b>
                        <span
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Services
                        </span>
                      </b>
                      ").
                    </span>
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Please carefully go through these terms of use ("
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Terms
                      </span>
                    </b>
                    ") and the privacy policy available at ("
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Privacy Policy
                      </span>
                    </b>
                    ") before you decide to access the Portal or avail the
                    Services made available on the Portal by ResearchON. These
                    Terms and the Privacy Policy together constitute a legal
                    agreement ("
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Agreement
                      </span>
                    </b>
                    ") between you and ResearchON in connection with your visit
                    to the Portal and your use of the Services.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    The Agreement is an electronic record published in
                    compliance of, and is governed by the provisions of,&nbsp;
                    <i>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        inter alia
                      </span>
                    </i>
                    , applicable provisions of the Indian Contract Act, 1872,
                    the (Indian) Information Technology Act, 2000 and the rules,
                    regulations, guidelines and clarifications framed there
                    under, including, the Information Technology (Reasonable
                    Security Practices and Procedures and Sensitive Personal
                    Information) Rules, 2011 (the&nbsp;
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        "Security Practices Rules"
                      </span>
                    </b>
                    ), and the Information Technology (Intermediaries
                    Guidelines) Rules, 2011 (the&nbsp;
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        "Intermediaries Rules"
                      </span>
                    </b>
                    ). The Agreement is generated by a computer system and does
                    not require any physical or digital signatures.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    The Agreement applies to, (a) healthcare service providers
                    and its team of doctors&nbsp;
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        ("Users")&nbsp;
                      </span>
                    </b>
                    using the Services of the Portal, (b) all the visitors or
                    users of the Portal, (iii) students, (iv) researchers, (v)
                    individual users (jointly and severally referred to as "
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        you
                      </span>
                    </b>
                    " or "
                    <b>
                      <span
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Users
                      </span>
                    </b>
                    " in these Terms).
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    These Terms constitute a legal and binding agreement between
                    the User and ResearchON. The Terms govern your use of the
                    Portal.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "18.75pt",
                    marginRight: "0in",
                    marginBottom: "3.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white",
                  }}
                >
                  <b>
                    <span
                      lang="EN-IN"
                      style={{
                        color: "#333333",
                      }}
                    >
                      Nature of the Terms
                    </span>
                  </b>
                </p>
                <ol style={{ marginTop: "0in" }} start={1} type="a">
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <span lang="EN-IN">
                      The Services may change from time to time, at the sole
                      discretion of ResearchON, and the Agreement will apply to
                      your visit to and your use of the Portal to avail the
                      Service, as well as to all information provided by you on
                      the Portal at any given point in time.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <span lang="EN-IN">
                      This Agreement defines the terms and conditions under
                      which you are allowed to use the Portal and describes the
                      manner in which we shall treat your account while you are
                      registered as a member with us. If you have any questions
                      about any part of the Agreement, feel free to contact us
                      at&nbsp;
                      <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                      .
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <span lang="EN-IN">
                      By downloading or accessing the Portal to use the
                      Services, you irrevocably accept all the conditions
                      stipulated in this Agreement, i.e., these Terms and
                      the&nbsp;
                      <b>
                        <span
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Privacy Policy
                        </span>
                      </b>
                      &nbsp;as available on the Portal and agree to abide by
                      them. This Agreement supersedes all previous oral and
                      written terms and conditions (if any) communicated to you
                      relating to your use of the Portal to avail the Services.
                      By availing any Service, you signify your acceptance of
                      the terms of this Agreement.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <span lang="EN-IN">
                      We reserve the right to modify or terminate any portion of
                      the Agreement for any reason and at any time, and such
                      modifications shall be informed to you in writing You
                      should read the Agreement at regular intervals. Your use
                      of the Portal following any such modification constitutes
                      your agreement to follow and be bound by the Agreement so
                      modified.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <span lang="EN-IN">
                      You acknowledge that you will be bound by this Agreement
                      for availing any of the Services offered by us. If you do
                      not agree with any part of the Agreement, please do not
                      use the Portal or avail any Services.
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <span lang="EN-IN">
                      Your access to use of the Portal and the Services will be
                      solely at the discretion of ResearchON.
                    </span>
                  </li>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "18.75pt",
                    marginRight: "0in",
                    marginBottom: "3.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white",
                  }}
                >
                  <b>
                    <span
                      lang="EN-IN"
                      style={{
                        color: "#333333",
                      }}
                    >
                      Conditions of use
                    </span>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    You must be 18 years of age or older to register, use the
                    Services, or visit or use the Portal in any manner. By
                    registering, visiting and using the Portal or accepting this
                    Agreement, you represent and warrant to ResearchON that you
                    are 18 years of age or older, and that you have the right,
                    authority and capacity to use the Portal and the Services
                    available through the Portal, and agree to and abide by this
                    Agreement.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "18.75pt",
                    marginRight: "0in",
                    marginBottom: "3.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "normal",
                    background: "white",
                  }}
                >
                  <b>
                    <span
                      lang="EN-IN"
                      style={{
                        color: "#333333",
                      }}
                    >
                      Terms of Use applicable to all Users
                    </span>
                  </b>
                </p>
                <ol style={{ marginTop: "0in" }} start={1} type="a">
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <b>
                      <span
                        lang="EN-IN"
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Account and data privacy
                      </span>
                    </b>
                  </li>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        The terms "<b>personal information</b>" and "
                        <b>sensitive personal data or information</b>" are
                        defined under the Security Practices Rules and are
                        reproduced in the Privacy Policy.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON may by its Services, collect information
                        relating to the devices through which you access the
                        Portal, and anonymous data of your usage. The collected
                        information will be used only for improving the quality
                        of ResearchONs’ services and to build new services.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Users are expected to read and understand the Privacy
                        Policy, so as to ensure that he or she has the knowledge
                        of&nbsp;
                        <i>
                          <span
                            style={{
                              border: "none windowtext 1.0pt",
                              padding: "0in",
                            }}
                          >
                            inter-alia
                          </span>
                        </i>
                        :
                      </span>
                    </li>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          the fact that certain information is being collected;
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          the purpose for which the information is being
                          collected;
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          the intended recipients of the information;
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          the nature of collection and retention of the
                          information; and
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          the name and address of the agency that is collecting
                          the information and the agency that will retain the
                          information; and
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          the various rights available to such Users in respect
                          of such information.
                        </span>
                      </li>
                    </ul>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON shall not be responsible in any manner for
                        the authenticity of the personal information or
                        sensitive personal data, or information supplied by the
                        User to ResearchON or to any other person acting on
                        behalf of ResearchON.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Users are responsible for maintaining the
                        confidentiality of their account access information and
                        password, if the User is registered on the Portal. The
                        User shall be responsible for all usage of the User’s
                        account and password, whether or not authorized by the
                        User. User shall immediately notify ResearchON of any
                        actual or suspected unauthorized use of the User’s
                        account or password. Although ResearchON will not be
                        liable for your losses caused by any unauthorized use of
                        your account, you may be liable for the losses of
                        ResearchON or such other parties as the case may be, due
                        to any unauthorized use of your account.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        If a User provides any information that is untrue,
                        inaccurate, not current or incomplete (or becomes
                        untrue, inaccurate, not current or incomplete), or
                        ResearchON has reasonable grounds to suspect that such
                        information is untrue, inaccurate, not current or
                        incomplete, ResearchON has the right to discontinue the
                        Services to the User at its sole discretion.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON may use such information collected from the
                        Users from time to time for the purposes of debugging
                        customer support related issues.
                        <br />
                        <br />
                      </span>
                    </li>
                  </ol>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <b>
                      <span
                        lang="EN-IN"
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Listing content and disseminating information
                      </span>
                    </b>
                  </li>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON collects, directly or indirectly, and
                        displays on the Portal, relevant information regarding
                        the profile and practice of the Users listed on the
                        Portal, such as their specialization, qualification,
                        fees, location, visiting hours, and similar details.
                        ResearchON takes reasonable efforts to ensure that such
                        information is updated at frequent intervals. ResearchON
                        cannot be held liable for any inaccuracies or
                        incompleteness, despite such reasonable efforts.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        The Services provided by ResearchON or any of its
                        employees or licensors or service providers are provided
                        on an "
                        <b>
                          <span
                            style={{
                              border: "none windowtext 1.0pt",
                              padding: "0in",
                            }}
                          >
                            as is
                          </span>
                        </b>
                        " and "
                        <b>
                          <span
                            style={{
                              border: "none windowtext 1.0pt",
                              padding: "0in",
                            }}
                          >
                            as available
                          </span>
                        </b>
                        " basis, and without any warranties or conditions
                        (express or implied, including the implied warranties of
                        merchantability, accuracy, fitness for a particular
                        purpose, title and non-infringement, arising by statute
                        or otherwise in law or from a course of dealing or usage
                        or trade). ResearchON does not provide or make any
                        representation, warranty or guarantee, express or
                        implied about the Portal or the Services. ResearchON
                        does not guarantee the accuracy or completeness of any
                        content or information provided by Users on the Portal.
                        To the fullest extent permitted by law, ResearchON
                        disclaims all liability arising out of the User’s use or
                        reliance upon the Portal, the Services, representations
                        and warranties made by other Users, the content or
                        information provided by the Users on the Portal, or any
                        opinion or suggestion given or expressed by ResearchON
                        or any User in relation to any User or services provided
                        by such User.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        The Portal may be linked to the website or mobile/tablet
                        application of third parties, affiliates and business
                        partners. ResearchON has no control over, and shall not
                        be liable or responsible for content, accuracy,
                        validity, reliability, quality of such websites or
                        mobile/tablet applications or which is made available
                        by/through the Portal. Inclusion of any link on the
                        Portal does not imply that ResearchON endorses the
                        linked site. User may use the links and these services
                        at User’s own risk.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON assumes no responsibility, and shall not be
                        liable for, any damages to, or viruses that may infect
                        User’s equipment on account of User’s access to, use of,
                        or browsing the Portal or the downloading of any
                        material, data, text, images, video content, or audio
                        content from the Portal. If a User is dissatisfied with
                        the Portal, User’s sole remedy is to discontinue using
                        the Portal.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        If ResearchON determines that you have provided
                        fraudulent, inaccurate, or incomplete information,
                        including through feedback, ResearchON reserves the
                        right to immediately suspend your access to the Portal
                        or any of your accounts with ResearchON and makes such
                        declaration on the Portal alongside your name/your
                        clinic’s name as determined by ResearchON for the
                        protection of its business and in the interests of
                        Users. You shall be liable to indemnify ResearchON for
                        any losses incurred as a result of your
                        misrepresentations or fraudulent feedback that has
                        adversely affected ResearchON or its Users.
                      </span>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={3} type="a">
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <b>
                      <span
                        lang="EN-IN"
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Content ownership and copyright conditions of access
                      </span>
                    </b>
                  </li>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        The contents listed on the Portal are (i) User generated
                        content, or (ii) belong to ResearchON. The information
                        that is collected by ResearchON directly or indirectly
                        from the Users shall belong to ResearchON. Copying of
                        the copyrighted content published by ResearchON on the
                        Portal for any commercial purpose or for the purpose of
                        earning profit will be a violation of copyright and
                        ResearchON reserves its rights under applicable law
                        accordingly.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON authorizes the User to view and access the
                        content available on or from the Portal solely for
                        ordering, receiving, delivering and communicating only
                        as per this Agreement. The contents of the Portal,
                        information, text, graphics, images, logos, button
                        icons, software code, design, and the collection,
                        arrangement and assembly of content on the Portal
                        (collectively,&nbsp;
                        <b>
                          <span
                            style={{
                              border: "none windowtext 1.0pt",
                              padding: "0in",
                            }}
                          >
                            "ResearchON Content"
                          </span>
                        </b>
                        ), are the property of ResearchON and are protected
                        under copyright, trademark and other laws. User shall
                        not modify the Portal Content or reproduce, display,
                        publicly perform, distribute, or otherwise use the
                        Portal Content in any way for any public or commercial
                        purpose or for personal gain.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        User shall not access the Services for purposes of
                        monitoring their availability, performance or
                        functionality, or for any other benchmarking or
                        competitive purposes.
                      </span>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={4} type="a">
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <b>
                      <span
                        lang="EN-IN"
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        User’s reviews and feedback
                      </span>
                    </b>
                  </li>
                </ol>
                <ol style={{ marginTop: "0in" }} start={4} type="a">
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        By using the Portal, you agree that any information
                        shared by you with ResearchON or with any Users will be
                        subject to our Privacy Policy.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        You are solely responsible for the content that you
                        choose to submit for publication on the Portal,
                        including any feedback, ratings, or reviews ("
                        <b>
                          <span
                            style={{
                              border: "none windowtext 1.0pt",
                              padding: "0in",
                            }}
                          >
                            Critical Content
                          </span>
                        </b>
                        ") relating to Users or other Users. The role of
                        ResearchON in publishing Critical Content is restricted
                        to that of an ‘intermediary’ under the Information
                        Technology Act, 2000. ResearchON disclaims all
                        responsibility with respect to the content of Critical
                        Content, and its role with respect to such content is
                        restricted to its obligations as an ‘intermediary’ under
                        the said Act. ResearchON shall not be liable to pay any
                        consideration to any User for re-publishing any content
                        across any of its platforms.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Your publication of reviews and feedback on the Portal
                        is governed by Clause 6 of these Terms. Without
                        prejudice to the detailed terms stated in Clause 6, you
                        hereby agree not to post or publish any content on the
                        Portal that (a) infringes any third-party intellectual
                        property or publicity or privacy rights, or (b) violates
                        any applicable law or regulation, including but not
                        limited to the Intermediaries Rules and Security
                        Practices Rules. ResearchON, at its sole discretion, may
                        choose not to publish your reviews and feedback, if so,
                        required by applicable law, and in accordance with
                        Clause 6 of these Terms. You agree that ResearchON may
                        contact you through telephone, email, SMS, or any other
                        electronic means of communication for the purpose of:
                      </span>
                    </li>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Obtaining feedback in relation to ResearchON or
                          ResearchONs’ Services; and/or
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Obtaining feedback in relation to any Users listed on
                          the Portal; and/or
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Resolving any complaints, information, or queries by
                          Users regarding your Critical Content;
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    and you agree to provide your fullest co-operation further
                    to such communication by ResearchON.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={5} type="a">
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <b>
                      <span
                        lang="EN-IN"
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Records
                      </span>
                    </b>
                  </li>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON provides Users with free functionalities on
                        the Portal towards uploading/storing of Users records
                        (the functionality and the records uploaded using such
                        functionalities shall hereinafter jointly be referred to
                        as "
                        <b>
                          <span
                            style={{
                              border: "none windowtext 1.0pt",
                              padding: "0in",
                            }}
                          >
                            Records
                          </span>
                        </b>
                        "). Information available in Users Records are of two
                        types:
                      </span>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    User-uploaded:
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={5} type="a">
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Information uploaded by you which is already available
                          with you prior to joining the Portal
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Information uploaded by you post joining the Portal
                          and which is based on your usage of the ResearchON
                          ecosystem, such as, new or current findings /
                          investigation reports / records / documents / images.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Portal-generated based on the information input by the User:
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Intelligent reports created at the back end by ResearchON
                    analytics towards assisting the Users in their research.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={5} type="a">
                  <ol style={{ marginTop: "0in" }} start={2} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Notwithstanding anything stated in these Terms and the
                        Privacy Policy, specific terms relating to such Records
                        are as stated below:
                      </span>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={5} type="a">
                  <ol style={{ marginTop: "0in" }} start={2} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Your Records is only created after you have signed up
                          and explicitly accepted these Terms.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Any Portal-generated Record, since the same is
                          generated on the basis of the information
                          input/uploaded by the User, is provided on an as-is
                          basis at the sole intent, risk and responsibility of
                          the User and ResearchON does not validate the said
                          information and makes no representation in connection
                          therewith.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          The Records are provided on an as-is basis. While we
                          strive to maintain the highest levels of service
                          availability, ResearchON is not liable for any
                          interruption that may be caused to your access of the
                          Services.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          It is your responsibility to keep your correct mobile
                          number and email ID updated in the Portal. ResearchON
                          is not responsible for any loss or inconvenience
                          caused due to your failure in updating the contact
                          details with ResearchON.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON uses industry–level security and encryption
                          to your Records. However, ResearchON does not
                          guarantee to prevent unauthorized access if you lose
                          your login credentials, or they are otherwise
                          compromised. In the event you are aware of any
                          unauthorized use or access, you shall immediately
                          inform ResearchON of such unauthorized use or access.
                          Please safeguard your login credentials and report any
                          actual suspected breach of account to&nbsp;
                          <span style={{ background: "white" }}>
                            team@researchon.
                          </span>
                          ai.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Certain of your Records, such as the ones
                          uploaded/recorded by you, can be deleted by yourself
                          at any time by logging into your account. However,
                          certain Records cannot be deleted by you, such as the
                          Portal-generated Records. In case you want to delete
                          the Portal-generated Records that belong to you, you
                          can do so by contacting our service support team.
                          However, only your Records will be deleted, and your
                          Records stored by your Users, if any, will continue to
                          be stored in their respective accounts.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          You may lose your "User created" Record, if the data
                          is not synced with the server.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          If the Record is unaccessed for a stipulated time, you
                          may not be able to access your Records due to security
                          reasons.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON is not liable if for any reason, the
                          Portal-generated Records are not delivered to you or
                          are delivered late despite best efforts.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON is not responsible or liable for any
                          content, fact, medical deduction or the language used
                          in your Records whatsoever.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON will follow the law of land in case of any
                          constitutional court or jurisdiction mandates to share
                          the Records for any reason.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          You acknowledge that the Users may engage ResearchON's
                          software or third-party software for the purposes of
                          the functioning of the Users’s business and
                          ResearchON's services including but not limited to the
                          usage and for storage of Records in India, in
                          accordance with the applicable laws.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          To the extent that your Records have been shared with
                          ResearchON or stored on any of the Portal products
                          used by Users’s you are visiting, and may in the past
                          have visited, You hereby agree to the storage of your
                          Records by ResearchON for ResearchON's services,
                          including but not limited to the usage and for storage
                          of Records in India, in accordance with the applicable
                          laws and further agree, upon creation of your account
                          with ResearchON, to the mapping of such Records as may
                          be available in ResearchONs’ database to your User
                          account.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      textAlign: "justify",
                      lineHeight: "13.5pt",
                      background: "white",
                    }}
                  >
                    <b>
                      <span
                        lang="EN-IN"
                        style={{
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        Interaction between registered Users for availing or
                        providing services
                      </span>
                    </b>
                  </li>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Terms for Users:
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={1} type="i">
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Every User on the Portal must be qualified in the area
                          of expertise that he/she represents as being his/her
                          qualification.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Users shall not share their personal, religious and/or
                          moral views with the others while providing services.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In the event of any breach of these terms and
                          conditions, ResearchON will take appropriate
                          action,&nbsp;
                          <i>
                            <span
                              style={{
                                border: "none windowtext 1.0pt",
                                padding: "0in",
                              }}
                            >
                              inter&nbsp;alia
                            </span>
                          </i>
                          ,&nbsp;ranging from issuing warnings to removal of
                          Users from the Portal, in its sole and absolute
                          discretion, on a case-to-case basis.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Any liability arising out of the consultation provided
                          by a User will be borne solely by the User. The Portal
                          is only a platform interface provided by ResearchON
                          for to the Users for consultation purposes.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          The Portal shall be audited on a regular basis by
                          ResearchON for ascertaining compliance by the User to
                          the terms and conditions provided herein, and the
                          Users consent to the same. It is clarified that the
                          said audit is not conducted for the purpose of quality
                          check or validation of responses issued by User.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Decision of ResearchON is final and binding on the
                          issues arising under these Terms.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={2} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Profile ownership and editing rights
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Users can update their profile information by writing to
                    ResearchON at&nbsp;
                    <span
                      style={{
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                        background: "white",
                      }}
                    >
                      <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                    </span>
                    <span style={{ background: "white" }}>.</span> ResearchON
                    reserves the right of ownership of all the User’s profile
                    and photographs and to moderate the changes or updates
                    requested by User. However, ResearchON takes the independent
                    decision whether to publish or reject the requests submitted
                    for the respective changes or updates. You hereby represent
                    and warrant that you are fully entitled under law to upload
                    all content uploaded by you as part of your profile or
                    otherwise while using ResearchONs’ services, and that no
                    such content breaches any third-party rights, including
                    intellectual property rights. Upon becoming aware of a
                    breach of the foregoing representation, ResearchON may
                    modify or delete parts of your profile information at its
                    sole discretion with or without notice to you.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={3} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Reviews and feedback display rights of ResearchON
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={3} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          All Critical Content is content created by the Users
                          of ResearchON. As a platform, ResearchON does not take
                          responsibility for Critical Content and its role with
                          respect to Critical Content is restricted to that of
                          an ‘intermediary’ under the Information Technology
                          Act, 2000.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON reserves the right to collect feedback and
                          Critical Content for all the Users listed on the
                          Portal.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON shall have no obligation to pre-screen,
                          review, flag, filter, modify, refuse or remove any or
                          all Critical Content from any Service, except as
                          required by applicable law.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          You understand that by using the Services you may be
                          exposed to Critical Content or other content that you
                          may find offensive or objectionable. ResearchON shall
                          not be liable for any effect on Users’s business due
                          to Critical Content of a negative nature. In these
                          respects, you may use the Service at your own risk.
                          ResearchON however, as an ‘intermediary, takes steps
                          as required to comply with applicable law as regards
                          the publication of Critical Content.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON will take down information under standards
                          consistent with applicable law and shall in no
                          circumstances be liable or responsible for Critical
                          Content, which has been created by the Users. The
                          principles set out in relation to third party content
                          in the terms of Service for the Portal shall be
                          applicable mutatis mutandis in relation to Critical
                          Content posted on the Portal.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          If ResearchON determines that you have provided
                          inaccurate information or enabled fraudulent feedback,
                          ResearchON reserves the right to immediately suspend
                          any of your accounts with ResearchON and makes such
                          declaration on the Portal alongside your name/your
                          clinics name as determined by ResearchON for the
                          protection of its business and in the interests of
                          Users.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Users understands that ResearchON shall not be liable,
                          under any event, for any comments or feedback given by
                          any of the Users in relation to the Services provided
                          by Users. The option of publishing or modifying or
                          moderating or masking (where required by law or norm
                          etc.) the feedback provided by Users shall be solely
                          at the discretion of ResearchON.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={4} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Independent services
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Your use of each Service confers upon you only the rights
                    and obligations relating to such Service, and not to any
                    other service that may be provided by ResearchON.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={5} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Sponsored ads
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={5} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON reserves the rights to display sponsored
                          ads on the Portal. These ads would be marked as
                          "Sponsored ads". Without prejudice to the status of
                          other content, ResearchON will not be liable for the
                          accuracy of information, or the claims made in the
                          Sponsored ads. ResearchON does not encourage the Users
                          to visit the Sponsored ads page or to avail any
                          services from them. ResearchON will not be liable for
                          the services of the providers of the Sponsored ads.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          You represent and warrant that you will use these
                          Services in accordance with applicable law. Any
                          contravention of applicable law as a result of your
                          use of these Services is your sole responsibility, and
                          ResearchON accepts no liability for the same.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={6} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Users undertaking
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    The Users shall at all times ensure that all the applicable
                    laws that govern the Users shall be followed and utmost care
                    shall be taken in terms of the research being carried on.
                    The Users shall be solely responsible for any data or
                    documents collected, uploaded on ResearchON. It is the Users
                    responsibility to ensure that the research/ study is
                    conducted in an ethical manner and any such research/ study
                    that would require seeking prior approval from any
                    committee, international or local body shall be taken prior
                    to the initiation of the study/ research. ResearchOn is only
                    a technology-based platform that enabled Users to conduct
                    research.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={7} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          Usage in promotional &amp; marketing materials
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    In recognition of the various offerings and services
                    provided by ResearchON to Users on the Portal, Users shall
                    (subject to its reasonable right to review and approve): (a)
                    allow ResearchON to include a brief description of the
                    services provided to Users in ResearchONs’ marketing,
                    promotional and advertising materials; (b) allow ResearchON
                    to make reference to Healthcare in case studies, and related
                    marketing materials; (c) serve as a reference to
                    Professional ResearchONs’ existing and potential clients;
                    (d) provide video logs, testimonials, e-mailers, banners,
                    interviews to the news media and provide quotes for press
                    releases; (e) make presentations at conferences; and/or (f)
                    use the Users’s name and/ or logo, brand images, tag lines
                    etc., within product literature, e-mailers, press releases,
                    social media and other advertising, marketing and
                    promotional materials.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={8} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          RIGHTS AND OBLIGATIONS RELATING TO CONTENT
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={8} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          As mandated by Regulation 3(2) of the Intermediaries
                          Rules, ResearchON hereby informs Users that they are
                          not permitted to host, display, upload, modify,
                          publish, transmit, update or share any information
                          that:
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={8} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <ol style={{ marginTop: "0in" }} start={1} type="a">
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            belongs to another person and to which the User does
                            not have any right to;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            is grossly harmful, harassing, blasphemous,
                            defamatory, obscene, pornographic, pedophilic,
                            libelous, invasive of another's privacy, hateful, or
                            racially, ethnically objectionable, disparaging,
                            relating or encouraging money laundering or
                            gambling, or otherwise unlawful in any manner
                            whatever;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">harm minors in any way;</span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            infringes any patent, trademark, copyright or other
                            proprietary rights;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            violates any law for the time being in force;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            deceives or misleads the addressee about the origin
                            of such messages or communicates any information
                            which is grossly offensive or menacing in nature;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">impersonate another person;</span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            contains software viruses or any other computer
                            code, files or programs designed to interrupt,
                            destroy or limit the functionality of any computer
                            resource;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            threatens the unity, integrity, defence, security or
                            sovereignty of India, friendly relations with
                            foreign states, or public order or causes incitement
                            to the commission of any cognizable offence or
                            prevents investigation of any offence or is
                            insulting any other nation.
                          </span>
                        </li>
                      </ol>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={8} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Users are also prohibited from:
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={8} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <ol style={{ marginTop: "0in" }} start={1} type="a">
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            violating or attempting to violate the integrity or
                            security of the Portal or any ResearchON content;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            transmitting any information (including job posts,
                            messages and hyperlinks) on or through the Portal
                            that is disruptive or competitive to the provision
                            of Services by ResearchON;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            intentionally submitting on the Portal any
                            incomplete, false or inaccurate information;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            making any unsolicited communications to other
                            Users;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            using any engine, software, tool, agent or other
                            device or mechanism (such as spiders, robots,
                            avatars or intelligent agents) to navigate or search
                            the Portal;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            attempting to decipher, decompile, disassemble or
                            reverse engineer any part of the Portal;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            copying or duplicating in any manner any of the
                            Portal content or other information available from
                            the Portal;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            framing or hot linking or deep linking any
                            ResearchON content.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            circumventing or disabling any digital rights
                            management, usage rules, or other security features
                            of the Software.
                          </span>
                        </li>
                      </ol>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={8} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON, upon obtaining knowledge by itself or been
                          brought to actual knowledge by an affected person in
                          writing or through email signed with electronic
                          signature about any such information as mentioned
                          above, shall be entitled to disable such information
                          that is in contravention of any of the provisions
                          hereunder. ResearchON shall also be entitled to
                          preserve such information and associated records for
                          at least 90 (ninety) days for production to
                          governmental authorities for investigation purposes.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In case of non-compliance with any applicable laws,
                          rules or regulations, or the Agreement (including the
                          Privacy Policy) by a User, ResearchON has the right to
                          immediately terminate the access or usage rights of
                          the User to the Portal and Services and to remove
                          non-compliant information from the Portal.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON may disclose or transfer User-generated
                          information to its affiliates or governmental
                          authorities in such manner as permitted or required by
                          applicable law, and you hereby consent to such
                          transfer. The Security Practices Rules only permit
                          ResearchON to transfer sensitive personal data or
                          information including any information, to any other
                          body corporate or a person in India, or located in any
                          other country, that ensures the same level of data
                          protection that is adhered to by ResearchON as
                          provided for under the Security Practices Rules, only
                          if such transfer is necessary for the performance of
                          the lawful contract between ResearchON or any person
                          on its behalf and the User or where the User has
                          consented to data transfer.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON respects the intellectual property rights
                          of others and we do not hold any responsibility for
                          any violations of any intellectual property rights.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={9} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          ADDITIONAL TERMS AND CONDITIONS FOR USE OF THE PORTAL
                          AND THE SERVICES
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={9} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON does not transfer either the title or the
                          intellectual property rights to the Portal or the
                          underlying software and source codes or its Services,
                          and ResearchON (or its licensors) retain full and
                          complete title to the same as well as all intellectual
                          property rights therein. User agrees to use the
                          Services and the materials provided therein only for
                          purposes that are permitted by: (a) this Agreement;
                          and (b) any applicable law, regulation or generally
                          accepted practices or guidelines in the relevant
                          jurisdictions. Information provided by a User to
                          ResearchON may be used, stored or re­published by
                          ResearchON or its affiliates even after the
                          termination of these Terms.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON offers its Services on "
                          <b>
                            <span
                              style={{
                                border: "none windowtext 1.0pt",
                                padding: "0in",
                              }}
                            >
                              as ­is
                            </span>
                          </b>
                          " basis and has the sole right to modify any feature
                          or customize them at its discretion and there shall be
                          no obligation to honour customization requests of any
                          User. The subscription fee hence charged is exclusive
                          of any customisation costs.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          User shall not access the Services of ResearchON if
                          the User or the organisation that he/she/it represents
                          is ResearchONs’ direct competitor, except with
                          ResearchONs’ prior written consent. In addition, the
                          User shall not access the Services for purposes of
                          monitoring their availability, performance or
                          functionality, or for any other benchmarking or
                          competitive purposes.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON will use commercially reasonable efforts to
                          make the Services on the Portal available 24 hours a
                          day, 7 days a week, except for (i) planned downtime
                          (of which ResearchON shall give at least 8 hours’
                          notice to Users and which ResearchON shall schedule to
                          the extent practicable during the weekend hours from
                          6:00 p.m. Indian Standard Time (IST) Friday to 6:00
                          a.m. Indian Standard Time (IST) Monday), or (ii) any
                          unavailability caused by circumstances beyond
                          ResearchONs’ reasonable control, including without
                          limitation, acts of God, acts of government, flood,
                          fire, earthquakes, civil unrest, acts of terror,
                          strikes or other labour problems, or internet service
                          provider failures or delays. ResearchON will provide
                          the Services only in accordance with applicable laws
                          and government regulations.&nbsp;
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Notwithstanding anything to the contrary contained
                          herein, ResearchON does not warrant that its Services
                          will always function without disruptions, delay or
                          errors. A number of factors may impact the use of the
                          Services (depending on the Services used) and native
                          mobile applications and may result in the failure of
                          your communications including but not limited to: your
                          local network, firewall, your internet service
                          provider, the public internet, your power supply and
                          telephony services. ResearchON takes no responsibility
                          for any disruption, interruption or delay caused by
                          any failure of or inadequacy in any of these items or
                          any other items over which we have no control.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In the event the Portal is not available due to
                          apparent default at ResearchONs’ end or is rendered
                          unusable, ResearchON may at its discretion extend the
                          subscription period of the User only by such number of
                          calendar days when the Services were not available.
                          However, you shall agree that ResearchON is not
                          responsible and will not be held liable for the any
                          failure of the intermediary services such as, internet
                          connectivity failure or telephonic disconnections.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON may, at its sole discretion, suspend User’s
                          ability to use or access the Services at any time
                          while ResearchON investigates complaints or alleged
                          violations of this Agreement, or for any other reason.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON reserves the right to use all information
                          captured in its Services in anonymised form for the
                          purpose of its Services improvements and providing
                          analytics and businesses intelligence to the third
                          parties. On the basis of such information, ResearchON
                          tries to make its Services more useful in following
                          way:
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={9} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <ol style={{ marginTop: "0in" }} start={1} type="a">
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">Promotion of new Services,</span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Analysing software usage patterns for improving
                            Services design and utility;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Analysing anonymised User’s information for research
                            and development of new technologies and any other
                            Services offerings;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Users can contact ResearchON immediately for
                            rectifications. ResearchON shall bear no liability
                            or responsibility in this regard.
                          </span>
                        </li>
                      </ol>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={9} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON reserves the right to add new
                          functionality, remove existing functionality, and
                          modify existing functionality to its Services as and
                          when it deems fit, and make any such changes available
                          in newer versions of its Services or native mobile
                          application or all of these at its discretion. All
                          Users of its Services will be duly notified upon
                          release of such newer versions and ResearchON reserves
                          the right to automatically upgrade all Users to the
                          latest version of its Portal as and when it deems fit.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={10} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          TERMINATION
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={10} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON reserves the right to suspend or terminate
                          a User’s access to the Portal and the Services with or
                          without notice and to exercise any other remedy
                          available under law, in cases where:
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={10} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <ol style={{ marginTop: "0in" }} start={1} type="a">
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Such User breaches any terms and conditions of the
                            Agreement;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            A third-party reports violation of any of its right
                            as a result of your use of the Services;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            ResearchON is unable to verify or authenticate any
                            information provide to ResearchON by a User;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            ResearchON has reasonable grounds for suspecting any
                            illegal, fraudulent or abusive activity on part of
                            such User; or
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            ResearchON believes in its sole discretion that
                            User’s actions may cause legal liability for such
                            User, other Users or for ResearchON or are contrary
                            to the interests of the Portal.
                          </span>
                        </li>
                      </ol>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={10} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Once temporarily suspended, indefinitely suspended or
                          terminated, the User may not continue to use the
                          Portal under the same account, a different account or
                          re-register under a new account. On termination of an
                          account due to the reasons mentioned herein, such User
                          shall no longer have access to data, messages, files
                          and other material kept on the Portal by such User.
                          The User shall ensure that he/she/it has continuous
                          backup of any medical services the User has rendered
                          in order to comply with the User’s record keeping
                          process and practices.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={11} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          LIMITATION OF LIABILITY
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={11} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In no event, including but not limited to negligence,
                          shall ResearchON, or any of its directors, officers,
                          employees, agents or content or service providers
                          (collectively, the "
                          <b>
                            <span
                              style={{
                                border: "none windowtext 1.0pt",
                                padding: "0in",
                              }}
                            >
                              Indemnified Persons
                            </span>
                          </b>
                          ") be liable for any direct, indirect, special,
                          incidental, consequential, exemplary or punitive
                          damages arising from, or directly or indirectly
                          related to, the use of, or the inability to use, the
                          Portal or the content, materials and functions related
                          thereto, the Services, User’s provision of information
                          via the Portal, even if such Protected Entity has been
                          advised of the possibility of such damages. In no
                          event shall the Indemnified Persons be liable for:
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={11} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <ol style={{ marginTop: "0in" }} start={1} type="a">
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            any content posted, transmitted, exchanged or
                            received by or on behalf of any User or other person
                            on or through the Portal;
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            any unauthorized access to or alteration of your
                            transmissions or data; or
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "13.5pt",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            any other matter relating to the Portal or the
                            Service.
                          </span>
                        </li>
                      </ol>
                    </ul>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={11} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In no event shall the total aggregate liability of the
                          Indemnified Persons to a User for all damages, losses,
                          and causes of action (whether in contract or tort,
                          including, but not limited to,&nbsp;negligence&nbsp;or
                          otherwise) arising from this Agreement or a User’s use
                          of the Portal or the Services exceed, in the aggregate
                          Rs. 1,00,000/- (Rupees One Lakh Only).
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={12} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          RETENTION AND REMOVAL
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Notwithstanding anything stated herien, ResearchON may
                    retain such information collected from Users from the Portal
                    or Services for as&nbsp;long&nbsp;as necessary, depending on
                    the type of information; purpose, means and modes of usage
                    of such information; and according to the Security Practices
                    Rules. Computer web server logs may be preserved as long as
                    administratively necessary.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={13} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          APPLICABLE LAW AND DISPUTE SETTLEMENT
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={13} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          You agree that this Agreement and any contractual
                          obligation between ResearchON and User will be
                          governed by the laws of India.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Any dispute, claim or controversy arising out of or
                          relating to this Agreement, including the
                          determination of the scope or applicability of this
                          Agreement to arbitrate, or your use of the Portal or
                          the Services or information to which it gives access,
                          shall be determined by arbitration in India, before a
                          sole arbitrator appointed by ResearchON. Arbitration
                          shall be conducted in accordance with the Arbitration
                          and Conciliation Act, 1996. The seat of such
                          arbitration shall be Bangalore, India. All proceedings
                          of such arbitration, including, without limitation,
                          any awards, shall be in the English language. The
                          award shall be final and binding on the parties to the
                          dispute.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Subject to the above Clause&nbsp;11(b), the courts at
                          Banglore, India shall have exclusive jurisdiction over
                          any disputes arising out of or in relation to this
                          Agreement, your use of the Portal or the Services or
                          the information to which it gives access.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={14} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          CONTACT INFORMATION GRIEVANCE OFFICER
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={14} type={1}>
                    <ul style={{ marginTop: "0in" }} type="disc">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          If a User has any questions concerning ResearchON, the
                          Portal, this Agreement, the Services, or anything
                          related to any of the foregoing, ResearchON customer
                          support can be reached at the following email
                          address:&nbsp;
                        </span>
                        <span lang="EN-IN" style={{ color: "black" }}>
                          <a href="mailto:team@researchon.ai">
                            <span
                              style={{
                                border: "none windowtext 1.0pt",
                                padding: "0in",
                                background: "white",
                              }}
                            >
                              team@researchon.ai
                            </span>
                          </a>
                        </span>
                        <span
                          lang="EN-IN"
                          style={{
                            background: "white",
                          }}
                        >
                          .
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In accordance with the Information Technology Act,
                          2000, and the rules made there under, if you have any
                          grievance with respect to the Portal or the Service,
                          including any discrepancies and grievances with
                          respect to processing of information, you can contact
                          our Grievance Officer at&nbsp;
                        </span>
                        <span lang="EN-IN" style={{ color: "black" }}>
                          <a href="mailto:team@researchon.ai">
                            <span
                              style={{
                                border: "none windowtext 1.0pt",
                                padding: "0in",
                                background: "white",
                              }}
                            >
                              team@researchon.ai
                            </span>
                          </a>
                        </span>
                        <span lang="EN-IN">
                          . Our officer will try to solve your concern duly and
                          in timely manner.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "13.5pt",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In the event you suffer as a result of access or usage
                          of our ResearchON by any person in violation&nbsp;of
                          Rule 3 of the Intermediaries Rules, please address
                          your grievance to the Grievance Office.
                        </span>
                      </li>
                    </ul>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={15} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          SEVERABILITY
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    If any provision of the Agreement is held by a court of
                    competent jurisdiction or arbitral tribunal to be
                    unenforceable under applicable law, then such provision
                    shall be excluded from this Agreement and the remainder of
                    the Agreement shall be interpreted as if such provision were
                    so excluded and shall be enforceable in accordance with its
                    terms; provided however that, in such event, the Agreement
                    shall be interpreted so as to give effect, to the greatest
                    extent consistent with and permitted by applicable law, to
                    the meaning and intention of the excluded provision as
                    determined by such court of competent jurisdiction or
                    arbitral tribunal.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type="a">
                  <ol style={{ marginTop: "0in" }} start={16} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        textAlign: "justify",
                        lineHeight: "13.5pt",
                        background: "white",
                      }}
                    >
                      <b>
                        <span
                          lang="EN-IN"
                          style={{
                            border: "none windowtext 1.0pt",
                            padding: "0in",
                          }}
                        >
                          WAIVER
                        </span>
                      </b>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "13.5pt",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    No provision of this Agreement shall be deemed to be waived
                    and no breach excused, unless&nbsp;such&nbsp;waiver or
                    consent shall be in writing and signed by ResearchON. Any
                    consent by ResearchON to, or a waiver by ResearchON of any
                    breach by you, whether expressed or implied, shall not
                    constitute consent to, waiver of, or excuse for any other
                    different or subsequent breach.
                  </span>
                </p>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h4>Unleash Your Research Potential with ResearchON</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <img
                    src="../assets/img/logo.png"
                    alt=""
                    className="img-fluid"
                    style={{ width: 160 }}
                  />
                  <p>
                    HSR Layout <br />
                    Bangalore - 560102
                    <br />
                    India <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+91 89717 02797"> +91 89717 02797 </a>
                    <br />
                    <strong>Email:</strong>{" "}
                    <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                    <br />
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/terms-and-conditions/">Terms of service</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/privacy-policy/">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://myportal.researchon.ai" target="_blank">
                        MyPortal
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/download-app/">Download App</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/data-security/">Data Secuirty</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Social Networks</h4>
                  <p>Please follow us on our social media platforms</p>
                  <div className="social-links mt-3">
                    <a href="#" className="instagram">
                      <i className="bx bxl-instagram" />
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
            <div className="copyright">
              COGNIDOC HEALTHTECH PRIVATE LIMITED. © 2024. All Rights Reserved
            </div>
            <div className="credits"></div>
          </div>
        </footer>
        {/* End Footer */}
      </>
    </div>
  );
};

export default TermsConditions;
