import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount/update
  }, []);
  return (
    <div>
      <>
        <div>
          <Helmet>
            <title>ResearchON</title>
            <meta name="title" content="ResearchON" />
            <meta
              name="keywords"
              content="ResearchON, Cognidoc Healthtech Private Limited, Dockare, Orbit, Healthcare"
            />
            <meta
              name="description"
              content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
            />
            <meta property="og:title" content="ResearchON" />
            <meta property="og:url" content="https://www.researchon.ai" />
            <meta
              property="og:description"
              content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
            />
            <meta
              property="og:image"
              content="https://www.researchon.ai/assets/img/researchon-logo.png"
            />
            <meta property="og:type" content="Healthtech" />
          </Helmet>
        </div>
        {/* ======= Header ======= */}
        <header id="header" className="fixed-top ">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="logo">
              <h1>
                <Link to="/">
                  <img
                    src="../assets/img/logo.png"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
              </h1>
              {/* Uncomment below if you prefer to use an image logo */}
              {/* <a href="index.html"><img src="../assets/img/logo.png" alt="" class="img-fluid"></a>*/}
            </div>
            <nav id="navbar" className="navbar">
              <ul>
                <li>
                  <a
                    className="getstarted scrollto"
                    href="https://myportal.researchon.ai"
                    target="_blank"
                  >
                    Access MyPortal
                  </a>
                </li>
              </ul>
              <i className="bi bi-list mobile-nav-toggle" />
            </nav>
            {/* .navbar */}
          </div>
        </header>
        {/* End Header */}
        <main id="main">
          {/* ======= Breadcrumbs Section ======= */}
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h2>Privacy Policy</h2>
                <ol>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Privacy Policy</li>
                </ol>
              </div>
            </div>
          </section>
          {/* End Breadcrumbs Section */}
          <section className="inner-page">
            <div className="container">
              <div className="WordSection1">
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                  }}
                >
                  <span lang="EN-IN">&nbsp;</span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    User (as defined herein) privacy is of paramount importance
                    to ResearchON ("<b>we</b>" / "<b>us</b>", including its
                    affiliates/ group companies).
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    This Privacy Policy is an electronic record generated by a
                    computer system and does not require any physical or digital
                    signatures. This Privacy Policy is published in compliance
                    with, inter alia, applicable provisions of the Information
                    Technology Act, 2000, Information Technology (Reasonable
                    Security Practices and Procedures and Sensitive Personal
                    Information) Rules, 2011 (the “<b>Rules</b>”) and
                    Information Technology (Intermediaries Guidelines) Rules,
                    2011.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Cognidoc Healthtech Private Limited has designed and
                    developed a mobile application/website-based console{" "}
                    <b>ResearchON </b>that is{" "}
                  </span>
                  <span
                    style={{
                      color: "black",
                    }}
                  >
                    a mobile and website-based secure application designed to
                    run on personal computers, smartphones and tablets and other
                    mobile computing devices, which facilitates{" "}
                    <i>inter alia</i>, - (i) Create multiple studies and
                    communicate and collaborate between health care
                    professionals, researchers, data collectors, graduates and
                    postgraduate students of the institute and allied health
                    professionals and other permitted staff of the institute,
                    and individual users, collectively, and (ii) enable the
                    researchers and their team members to create, collect and
                    communicate participant related data. Researchers and their
                    respective teams which include Users and student groups to
                    communicate and perform collaborative research and audit by
                    building electronic Data collection forms
                  </span>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    {" "}
                    (hereinafter referred to as the “<b>Services</b>”).
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    This privacy policy ("<b>Privacy Policy</b>") explains how
                    ResearchON collects, uses, shares, discloses and protects
                    Personal Information (as defined herein) of the end-users of
                    the ResearchON App and the researchers using the Services
                    (jointly and severally referred to as “<b>you</b>” or “
                    <b>Users</b>” in this Privacy Policy). You are advised to
                    familiarize yourself with this Privacy Policy.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Your use of and access to the Services is subject to this
                    Privacy Policy read with the ResearchON Terms of Use
                    available at&nbsp;
                  </span>
                  <span lang="EN-IN" style={{ color: "black" }}>
                    <a href="https://www.researchon.ai/terms-and-conditions">
                      <span
                        style={{
                          color: "#337AB7",
                          border: "none windowtext 1.0pt",
                          padding: "0in",
                        }}
                      >
                        HERE
                      </span>
                    </a>
                  </span>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    , and any other agreement entered into by the Research
                    Institutes with ResearchON. Any capitalized term used but
                    not defined in this Privacy Policy shall have the meaning
                    attributed to it in the ResearchON Terms of Use.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={1} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "18.75pt",
                      marginBottom: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">ABOUT THIS PRIVACY POLICY</span>
                    </b>
                  </li>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    This Privacy Policy provides information with regards to,
                    (a) the type of information collected from the Users,
                    including Personal Information and Sensitive Personal Data
                    or Information (as defined herein) relating to an
                    individual, (b) the purpose, means and modes of collection,
                    usage, processing, retention and destruction of such
                    information, and (c) the process how and to whom ResearchON
                    will disclose such information.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={2} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "18.75pt",
                      marginBottom: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">ACCEPTANCE</span>
                    </b>
                  </li>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    By using the Services or by otherwise giving us your
                    information, you will be deemed to have read, understood and
                    agreed to the practices and policies outlined in this
                    Privacy Policy and agree to be bound by this Privacy Policy.
                    You hereby consent to our collection, use and sharing,
                    disclosure of your information as described in this Privacy
                    Policy. We reserve the right to change, modify, add or
                    delete portions of the terms of this Privacy Policy, at our
                    sole discretion, at any time. If you do not agree with this
                    Privacy Policy at any time, do not use any of the Services
                    or give us any of your information. if you use the Services
                    on behalf of another person or entity, you represent that
                    you are authorised by such person or entity to (i) accept
                    this Privacy Policy on such person’s or entity’s behalf, and
                    (ii) consent on behalf of such person or entity to our
                    collection, use and disclosure of such person’s or entity’s
                    information as described in this Privacy Policy.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={3} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "18.75pt",
                      marginBottom: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">
                        COLLECTION AND USE OF PERSONAL INFORMATION
                      </span>
                    </b>
                  </li>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    Certain Services provided on the ResearchON App require us
                    to know who you are so that we are more equipped to service
                    your requirements. When you access the Services, we may ask
                    you to voluntarily provide us with certain information that
                    personally identifies you or could be used to personally
                    identify you. You hereby consent to the collection of such
                    information by ResearchON. Without prejudice to the
                    generality of the above, information collected by us from
                    you may include (but is not limited to) the following:
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    textIndent: "-.25in",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    1.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  <b>
                    <u>
                      <span
                        lang="EN-IN"
                        style={{
                          color: "#333333",
                        }}
                      >
                        Account Data
                      </span>
                    </u>
                  </b>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    : When registering to use our Services, ResearchON requires
                    you to provide your personal contact information, such as
                    name, company name, address, phone number, and email
                    address, details of any affiliations with an institution/
                    organisation ("<b>Contact Information</b>"). When purchasing
                    our Services, ResearchON may require you to provide billing
                    information, such as billing name and address, credit card
                    number, and the number of employees that will be using the
                    product or services ("
                    <b>Billing Information</b>"). Contact Information, Billing
                    Information, and Optional Information about customers are
                    referred to collectively as "<b>Account Data</b>" We use
                    Account Data to perform the Services requested. We do not
                    store your credit card data.
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpLast"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    textIndent: "-.25in",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    2.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  <b>
                    <u>
                      <span
                        lang="EN-IN"
                        style={{
                          color: "#333333",
                        }}
                      >
                        Your Content
                      </span>
                    </u>
                  </b>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    : When you use our Services, you provide us with things like
                    your data, reports, messages, contacts, images and so on ("
                    <b>Your Content</b>"). Our Services are designed to make it
                    simple for you to collect and manage Your Content, share
                    with others, and work across multiple devices. To make that
                    possible, we store, process, and transmit Your Content, as
                    well as information related to it. This related information
                    can be things like your Contact Information that makes it
                    easier to collaborate and share Your Content with others.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpFirst"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    textIndent: "-.25in",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    3.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  <b>
                    <u>
                      <span
                        lang="EN-IN"
                        style={{
                          color: "#333333",
                        }}
                      >
                        Usage
                      </span>
                    </u>
                  </b>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    : We collect information related to how you use the
                    Services, including actions you take in your account. This
                    is used to conduct research and development for the further
                    development of our Services in order to provide you a more
                    intuitive experience, and also to provide you support.
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    textIndent: "-.25in",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    4.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  <b>
                    <u>
                      <span
                        lang="EN-IN"
                        style={{
                          color: "#333333",
                        }}
                      >
                        Device Information
                      </span>
                    </u>
                  </b>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    : We also collect information from and about the devices you
                    use to access the Services. This includes things like IP
                    addresses, the type of browser and device you use, the web
                    page you visited before coming to our sites, and identifiers
                    associated with your devices. Your devices (depending on
                    their settings) may also transmit location information to
                    the Services. This helps us improve our Services and provide
                    you support.
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    textIndent: "-.25in",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    5.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  <b>
                    <u>
                      <span
                        lang="EN-IN"
                        style={{
                          color: "#333333",
                        }}
                      >
                        Cookies and other technologies
                      </span>
                    </u>
                  </b>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    : We use technologies like cookies and web beacons to
                    provide, improve, and protect our Services. For example,
                    cookies Services and things like remembering your username
                    for your next visit, understanding how you are interacting
                    with our Services, and improving them based on that
                    information. You can set your browser to not accept cookies,
                    but this may limit your ability to use the Services.
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpMiddle"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraphCxSpLast"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.0in",
                    textAlign: "justify",
                    textIndent: "-.25in",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    6.
                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </span>
                  <b>
                    <u>
                      <span
                        lang="EN-IN"
                        style={{
                          color: "#333333",
                        }}
                      >
                        Aggregate Data Sets
                      </span>
                    </u>
                  </b>
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    : ResearchON may also use Your Content to create aggregate,
                    anonymized data sets ("<b>Data Sets</b>"). Data Sets are not
                    associated with any individual or user account and are thus
                    anonymous information. ResearchON may use these Aggregate
                    Data Sets to improve our Services and for research purposes.
                    If you do not consent to the use of Your Data to create Data
                    Sets, please "opt-out" by contacting us at
                    <a href="mailto:team@researchon.ai">
                      team@researchon.ai
                    </a>{" "}
                    and your data will not be included as of the date of
                    notification. If you have already provided us your
                    ‘opt-out’, we will continue to honor that. The information
                    collected from you by ResearchON may constitute ‘Personal
                    Information’ or ‘Sensitive Personal Data or Information’
                    under the Rules.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    “<b>Personal Information</b>” is defined under the Rules to
                    mean any information that relates to a natural person,
                    which, either directly or indirectly, in combination with
                    other information available or likely to be available to a
                    body corporate, is capable of identifying such person.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    The Rules further define “
                    <b>Sensitive Personal Data or Information</b>” of a person
                    to mean personal information about that person relating to:
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={3} type={1}>
                  <ol style={{ marginTop: "0in" }} start={7} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">passwords;</span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        financial information such as bank accounts, credit and
                        debit card details or other payment instrument details;
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        physical, physiological and mental health condition;
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">sexual orientation;</span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">medical records and history;</span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">biometric information;</span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        information received by body corporate under lawful
                        contract or otherwise;
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        visitor details as provided at the time of registration
                        or thereafter; and
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">call data records.</span>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    ResearchON will be free to use, collect and disclose
                    information that is freely available in the public domain
                    without your consent.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={4} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginTop: "18.75pt",
                      marginBottom: "3.75pt",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">PRIVACY STATEMENTS</span>
                    </b>
                  </li>
                </ol>
                <ol style={{ marginTop: "0in" }} start={4} type={1}>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginBottom: "0in",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        General conditions applicable to all Users
                      </span>
                    </li>
                    <ol style={{ marginTop: "0in" }} start={1} type="i">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Each User’s use of and access to the Services is
                          conditional upon their acceptance of the ResearchON
                          Terms of Use, which also involves acceptance of the
                          terms of this Privacy Policy. Any User that does not
                          agree with any provisions of the same has the option
                          to discontinue the Services provided by ResearchON
                          immediately.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          All the information provided to ResearchON by a User,
                          including Personal Information or any Sensitive
                          Personal Data or Information, is voluntary. You
                          understand that ResearchON may use certain information
                          of yours, which has been designated as Personal
                          Information or ‘Sensitive Personal Data or
                          Information’ under the Rules, (a) for the purpose of
                          providing you the Services, (b) for communication
                          purpose so as to provide you a better way of booking
                          appointments and for obtaining feedback in relation to
                          the User and their practice, (c) debugging customer
                          support related issues, (d) for the purpose of
                          contacting you to complete any transaction if you do
                          not complete a transaction after having provided us
                          with your contact information in the course of
                          completing such steps that are designed for completion
                          of the transaction. ResearchON also reserves the right
                          to use information provided by you for the following
                          purposes:
                        </span>
                      </li>
                      <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Publishing such information on the ResearchON App.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Contacting Users for taking product and Service
                            feedback.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Analyzing software usage patterns for improving
                            product design and utility.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Analyzing anonymized practice information for
                            commercial use.
                          </span>
                        </li>
                      </ol>
                    </ol>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: "1.5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    If you have voluntarily provided your Personal Information
                    to ResearchON for any of the purposes stated above, you
                    hereby consent to such collection and use of such
                    information by ResearchON. However, ResearchON shall not
                    contact you on your telephone number(s) for any purpose, if
                    such telephone number is registered with the Do Not Call
                    registry (“
                    <b>DNC Registry</b>”) under the Personal Data Protection
                    Act, 2012, without your express, clear and un-ambiguous
                    written consent.
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={4} type={1}>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <ol style={{ marginTop: "0in" }} start={3} type="i">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Collection, use and disclosure of information which
                          has been designated as Personal Information or
                          Sensitive Personal Data or Information’ under the
                          Rules requires your express consent. By affirming your
                          assent to this Privacy Policy, you provide your
                          consent to such use, collection and disclosure as
                          required under applicable law.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON does not control or endorse the content,
                          messages or information found in any Services and,
                          therefore, ResearchON specifically disclaims any
                          liability with regard to the Services and any actions
                          resulting from your participation in any Services, and
                          you agree that you waive any claims against ResearchON
                          relating to same, and to the extent such waiver may be
                          ineffective, you agree to release any claims against
                          ResearchON relating to the same.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          You are responsible for maintaining the accuracy of
                          the information you submit to us, such as your contact
                          information provided as part of account registration.
                          If your personal information changes, you may correct,
                          delete inaccuracies, or amend information by making
                          the change on our member information page or by
                          contacting us through&nbsp;
                          <a href="mailto:team@researchon.ai">
                            team@researchon.ai
                          </a>
                          . We will make good faith efforts to make requested
                          changes in our then active databases as soon as
                          reasonably practicable. If you provide any information
                          that is untrue, inaccurate, out of date or incomplete
                          (or becomes untrue, inaccurate, out of date or
                          incomplete), or ResearchON has reasonable grounds to
                          suspect that the information provided by you is
                          untrue, inaccurate, out of date or incomplete,
                          ResearchON may, at its sole discretion, discontinue
                          the provision of the Services to you. There may be
                          circumstances where ResearchON will not correct,
                          delete or update your Personal Data, including (a)
                          where the Personal Data is opinion data that is kept
                          solely for evaluative purpose; and (b) the Personal
                          Data is in documents related to a prosecution if all
                          proceedings relating to the prosecution have not been
                          completed.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          If you wish to cancel your account or request that we
                          no longer use your information to provide you
                          Services, contact us through&nbsp;team@researchon.io.
                          We will retain your information for as long as your
                          account with the Services is active and as needed to
                          provide you the Services. We shall not retain such
                          information for longer than is required for the
                          purposes for which the information may lawfully be
                          used or is otherwise required under any other law for
                          the time being in force. After a period of time, your
                          data may be anonymized and aggregated, and then may be
                          held by us as long as necessary for us to provide our
                          Services effectively, but our use of the anonymized
                          data will be solely for analytic purposes. Please note
                          that your withdrawal of consent, or cancellation of
                          account may result in ResearchON being unable to
                          provide you with its Services or to terminate any
                          existing relationship ResearchON may have with you.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          If you wish to opt-out of receiving non-essential
                          communications such as promotional and
                          marketing-related information regarding the Services,
                          please send us an email at&nbsp;
                          <a href="mailto:team@researchon.ai">
                            team@researchon.ai
                          </a>
                          .
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON may require the User to pay with a credit
                          card, wire transfer, debit card or cheque for Services
                          for which subscription amount(s) is/are payable.
                          ResearchON will collect such User’s credit card number
                          and/or other financial institution information such as
                          bank account numbers and will use that information for
                          the billing and payment processes, including but not
                          limited to the use and disclosure of such credit card
                          number and information to third parties as necessary
                          to complete such billing operation. Verification of
                          credit information, however, is accomplished solely by
                          the User through the authentication process. User’s
                          credit-card/debit card details are transacted upon
                          secure sites of approved payment gateways which are
                          digitally under encryption, thereby providing the
                          highest possible degree of care as per current
                          technology. However, ResearchON provides you an option
                          not to save your payment details. User is advised,
                          however, that internet technology is not completely
                          proof safe, and User should exercise discretion on
                          using the same. ResearchON shall not undertake any
                          liability of any nature for any incidents occurring
                          out of the use of online payment methods by the User.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Due to the communications standards on the Internet,
                          when a User or anyone who visits the ResearchON App,
                          ResearchON automatically receives the URL of the site
                          from which anyone visits. ResearchON also receives the
                          Internet Protocol (IP) address of each User’s computer
                          (or the proxy server a User used to access the World
                          Wide Web), User’s computer operating system and type
                          of web browser the User is using, email patterns, as
                          well as the name of User’s ISP. This information is
                          used to analyze overall trends to help ResearchON
                          improve its Service. The linkage between User’s IP
                          address and User’s personally identifiable information
                          is not shared with or disclosed to third parties.
                          Notwithstanding the above, ResearchON may share and/
                          or disclose some of the aggregate findings (not the
                          specific data) in anonymized form (i.e.,
                          non-personally identifiable) with advertisers,
                          sponsors, investors, strategic partners, and others in
                          order to help grow its business.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          The ResearchON App uses temporary cookies to store
                          certain (that is not sensitive personal data or
                          information) that is used by ResearchON and its
                          service providers for the technical administration of
                          the ResearchON App, research and development, and for
                          User administration. In the course of serving
                          advertisements or optimizing services to its Users,
                          ResearchON may allow authorized third parties to place
                          or recognize a unique cookie on the User’s browser.
                          The cookies however, do not store any Personal
                          Information of the User. You may adjust your internet
                          browser to disable cookies. If cookies are disabled
                          you may still use the ResearchON App, but the
                          ResearchON App may be limited in the use of some of
                          the features.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Identity theft and the fraudulent practice currently
                          known as "phishing" are of great concern to
                          ResearchON. Phishing is an attempt to acquire Personal
                          Information / Sensitive Personal Data or Information
                          which the legitimate organization already has.
                          Safeguarding your information to help protect you from
                          identity theft is our top priority. We do not and will
                          not, at any time, request your credit card information
                          or national identification numbers in a non-secure or
                          unsolicited e-mail or telephone communication.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          As part of the registration/application creation and
                          submission process that is available to Users on this
                          ResearchON App, certain information, including
                          Personal Information or Sensitive Personal Data or
                          Information is collected from the Users.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          In order to have access to all the features and
                          benefits on our ResearchON App, a User must first
                          create an account on our ResearchON App. To create an
                          account, a User is required to provide the following
                          information, which such User recognizes and expressly
                          acknowledges is Personal Information allowing others,
                          including ResearchON, to identify the User: name, User
                          ID, email address, country, ZIP/postal code, age,
                          phone number, password chosen by the User and valid
                          financial account information. Other information if
                          any requested on the registration page, including the
                          ability to receive promotional offers from ResearchON,
                          is optional. ResearchON may, in future, include other
                          optional requests for information from the User to
                          help ResearchON to customize the ResearchON App to
                          deliver personalized information to the User.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          This Privacy Policy applies to Services that are owned
                          and operated by ResearchON. ResearchON does not
                          exercise control over the sites displayed as search
                          results or links from within its Services. These other
                          sites may place their own cookies or other files on
                          the Users’ computer, collect data or solicit personal
                          information from the Users, for which ResearchON is
                          not responsible or liable. Accordingly, ResearchON
                          does not make any representations concerning the
                          privacy practices or policies of such third parties or
                          terms of use of such websites, nor does ResearchON
                          guarantee the accuracy, integrity, or quality of the
                          information, data, text, software, sound, photographs,
                          graphics, videos, messages or other materials
                          available on such websites. The inclusion or exclusion
                          does not imply any endorsement by ResearchON of the
                          website, the website's provider, or the information on
                          the website. If you decide to visit a third-party
                          website linked to the ResearchON App, you do this
                          entirely at your own risk. ResearchON encourages the
                          User to read the privacy policies of that website.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          The ResearchON App may enable User to communicate with
                          other Users or to post information to be accessed by
                          others, whereupon other Users may collect such data.
                          Such Users, including any moderators or
                          administrators, are not authorized ResearchON
                          representatives or agents, and their opinions or
                          statements do not necessarily reflect those of
                          ResearchON, and they are not authorized to bind
                          ResearchON to any contract. ResearchON hereby
                          expressly disclaims any liability for any reliance or
                          misuse of such information that is made available by
                          Users or visitors in such a manner.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON does not collect information about the
                          visitors of the ResearchON App from other sources,
                          such as public records or bodies, or private
                          organisations, save and except for the purposes of
                          registration of the Users (the collection, use,
                          storage and disclosure of which each User must agree
                          to under the ResearchON Terms of Use in order for
                          ResearchON to effectively render the Services).
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON maintains a strict "No-Spam" policy, which
                          means that ResearchON does not intend to sell, rent or
                          otherwise give your e-mail address to a third party
                          without your consent.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON has implemented best international market
                          practices and security policies, rules and technical
                          measures to protect the personal data that it has
                          under its control from unauthorised access, improper
                          use or disclosure, unauthorised modification and
                          unlawful destruction or accidental loss. However, for
                          any data loss or theft due to unauthorized access to
                          the User’s electronic devices through which the User
                          avails the Services, ResearchON shall not be held
                          liable for any loss whatsoever incurred by the User.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON implements reasonable security practices
                          and procedures and has a comprehensive documented
                          information security programme and information
                          security policies that contain managerial, technical,
                          operational and physical security control measures
                          that are commensurate with respect to the information
                          being collected and the nature of ResearchONs’
                          business.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON takes your right to privacy very seriously
                          and other than as specifically stated in this Privacy
                          Policy, will only disclose your Personal Information
                          in the event it is required to do so by law, rule,
                          regulation, law enforcement agency, governmental
                          official, legal authority or similar requirements or
                          when ResearchON, in its sole discretion, deems it
                          necessary in order to protect its rights or the rights
                          of others, to prevent harm to persons or property, to
                          fight fraud and credit risk, or to enforce or apply
                          the ResearchON Terms of Use.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          All the statements in this Privacy Policy apply to all
                          Users, and all Users are therefore required to read
                          and understand the privacy statements set out herein
                          prior to submitting any Personal Information or
                          Sensitive Personal Data or Information to ResearchON,
                          failing which they are required to exit from
                          ResearchON immediately.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          If you have inadvertently submitted any such
                          information to ResearchON prior to reading the privacy
                          statements set out herein, and you do not agree with
                          the manner in which such information is collected,
                          processed, stored, used or disclosed, then you may
                          access, modify and delete such information by using
                          options provided on the ResearchON App. In addition,
                          you can, by sending an email to&nbsp;
                          <a href="mailto:team@researchon.ai">
                            team@researchon.ai
                          </a>
                          , inquire whether ResearchON is in possession of your
                          Personal Information, and you may also request
                          ResearchON to delete and destroy all such information.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          User’s personally identifiable information, which they
                          choose to provide on the ResearchON App is used to
                          help the Users describe/identify themselves. Other
                          information that does not personally identify the
                          Users as an individual or an entity, is collected by
                          ResearchON from Users (such as, patterns of
                          utilization described above) and is exclusively owned
                          by ResearchON. ResearchON may also use such
                          information in an aggregated or non-personally
                          identifiable form for research, statistical analysis
                          and business intelligence purposes, and may sell or
                          otherwise transfer such research, statistical or
                          intelligence data in an aggregated or non-personally
                          identifiable form to third parties and affiliates. In
                          particular, ResearchON reserves with it the right to
                          use anonymized User demographics information and
                          anonymized User information for the following
                          purposes:
                        </span>
                      </li>
                      <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Analyzing software usage patterns for improving
                            product design and utility.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Analyzing such information for research and
                            development of new technologies.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Using analysis of such information in other
                            commercial product offerings of ResearchON.
                          </span>
                        </li>
                        <li
                          className="MsoNormal"
                          style={{
                            color: "#333333",
                            marginTop: "3.75pt",
                            textAlign: "justify",
                            lineHeight: "115%",
                            background: "white",
                          }}
                        >
                          <span lang="EN-IN">
                            Sharing analysis of such information with third
                            parties for commercial use.
                          </span>
                        </li>
                      </ol>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON may conduct a User survey to collect
                          information about Users' preferences. These surveys
                          are optional and if Users choose to respond, their
                          responses will be kept anonymous. Similarly,
                          ResearchON may offer contests to qualifying Users in
                          which we ask for contact and demographic information
                          such as name, email address and mailing address. The
                          demographic information that ResearchON collects in
                          the registration process and through surveys is used
                          to help ResearchON improve its Services to meet the
                          needs and preferences of Users.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          All ResearchON employees and data processors, who have
                          access to, and are associated with the processing of
                          Sensitive Personal Data or Information, are obliged to
                          respect the confidentiality of every Users’ Personal
                          Information or Sensitive Personal Data and
                          Information. ResearchON has put in place procedures
                          and technologies as per good industry practices and in
                          accordance with the applicable laws, to maintain
                          security of all Personal Information from the point of
                          collection to the point of destruction. Any
                          third-party data processor to which ResearchON
                          transfers Personal Information shall have to agree to
                          comply with those procedures and policies or put in
                          place adequate measures on their own.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          ResearchON may also disclose, or transfer Users’
                          personal and other information provided by a User, to
                          a third party as part of reorganization or a sale of
                          the assets of a ResearchON corporation division or
                          company. Any third party to which ResearchON transfers
                          or sells its assets will have the right to continue to
                          use the Personal Information and other information
                          that Users provide to us, in accordance with the
                          ResearchON Terms of Use.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          To the extent necessary to provide Users with the
                          Services, ResearchON may provide their Personal
                          Information to third party contractors who work on
                          behalf of or with ResearchON to provide Users with
                          such Services, to help ResearchON communicate with
                          Users or to maintain the ResearchON App. Generally,
                          these contractors do not have any independent right to
                          share this information, however certain contractors
                          who provide services on the ResearchON App, including
                          the providers of online communications services, may
                          use and disclose the Personal Information collected in
                          connection with the provision of these Services in
                          accordance with their own privacy policies. In such
                          circumstances, you consent to us disclosing your
                          Personal Information to contractors, solely for the
                          intended purposes only.
                          <br />
                          <br />
                        </span>
                      </li>
                    </ol>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginBottom: "0in",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Specific conditions applicable to all Users:
                      </span>
                    </li>
                    <ol style={{ marginTop: "0in" }} start={1} type="i">
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          As part of the registration as well as the application
                          creation and submission process that is available to
                          Users on ResearchON, certain information, including
                          Personal Information or Sensitive Personal Data or
                          Information is collected from the Users.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          All the statements in this Privacy Policy apply to all
                          Users, and all Users are therefore required to read
                          and understand the privacy statements set out herein
                          prior to submitting any Personal Information or
                          Sensitive Personal Data or Information to ResearchON,
                          failing which you are required to leave the Services,
                          including the ResearchON App immediately.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Users personally identifiable information, which you
                          choose to provide to ResearchON, is used to help you
                          describe and identify yourselves. The ResearchON App
                          also enables a User to allow access to his/ her
                          contact list. If you allow access to the contact list,
                          ResearchON would use such information for enabling
                          access to your contacts while you are using the
                          ResearchON App and add members into a team or group on
                          the ResearchON App for research collaboration and
                          other such collaborative features. You will be the
                          owner of your information and you consent to
                          ResearchON collecting, using, processing and/or
                          disclosing this information for the purposes herein
                          stated. ResearchON App does not store or upload the
                          contact list or any other information on the servers.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          Users shall have the option to allow the ResearchON
                          App to access to its devices’ camera on the Portal to
                          upload documents. The same shall be securely stored on
                          the ResearchON’s server and shall be solely accessible
                          by you/Institution. We prioritise the security and
                          confidentiality of these uploads and ensure that the
                          same is protected.
                        </span>
                      </li>
                      <li
                        className="MsoNormal"
                        style={{
                          color: "#333333",
                          marginTop: "3.75pt",
                          textAlign: "justify",
                          lineHeight: "115%",
                          background: "white",
                        }}
                      >
                        <span lang="EN-IN">
                          The ResearchON Application may request permission from
                          the Users to upload their photographs, other images,
                          and any other relevant documents from their devices’
                          storage onto the Portal, which data shall be used by
                          ResearchON towards the research. The data uploaded by
                          the Users shall be securely stored on the ResearchON’s
                          server and shall be solely accessible by the Users.
                          <br />
                          <br />
                        </span>
                      </li>
                    </ol>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={5} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">CONFIDENTIALITY AND SECURITY</span>
                    </b>
                  </li>
                </ol>
                <ol style={{ marginTop: "0in" }} start={5} type={1}>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Your Personal Information is maintained by ResearchON in
                        electronic form using technologically appropriate
                        methods. Such information may also be converted to
                        physical form from time to time. ResearchON takes all
                        necessary precautions to protect your Personal
                        Information both online and off-line and implements
                        reasonable security practices and measures including
                        certain managerial, technical, operational and physical
                        security control measures that are commensurate with
                        respect to the information being collected and the
                        nature of ResearchONs’ business.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        No administrator at ResearchON will have knowledge of
                        your password. It is important for you to protect
                        against unauthorized access to your password, your
                        computer and your mobile phone. Ensure that you log off
                        from the ResearchON App when finished. ResearchON does
                        not undertake any liability for any unauthorised use of
                        your account and password. If you suspect any
                        unauthorized use of your account, you must immediately
                        notify ResearchON by sending an email to&nbsp;
                        <a href="mailto:team@researchon.ai">
                          team@researchon.ai
                        </a>
                        . You shall be liable to indemnify ResearchON due to any
                        loss suffered by it due to such unauthorized use of your
                        account and password.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON makes all User information accessible to its
                        employees, agents or partners and third parties only on
                        a need-to-know basis and binds only its employees to
                        strict confidentiality obligations.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Part of the functionality of ResearchON is assisting the
                        Users to maintain and organise such information.
                        ResearchON may, therefore, retain and submit all such
                        records to the appropriate authorities, or to Users who
                        request access to such information.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        Notwithstanding the above, ResearchON is not responsible
                        for the confidentiality, security or distribution of
                        your Personal Information by our partners and third
                        parties outside the scope of our agreement with such
                        partners and third parties. Further, ResearchON shall
                        not be responsible for any breach of security or for any
                        actions of any third parties or events that are beyond
                        the reasonable control of ResearchON including but not
                        limited to, acts of government, computer hacking,
                        unauthorised access to computer data and storage device,
                        computer crashes, breach of security and encryption,
                        poor quality of Internet service or telephone service of
                        the User etc.
                      </span>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "0in",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    <br />
                    <br />
                    <br />
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={6} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">
                        MINOR'S USE OF THE PORTAL AND/ OR SERVICES
                      </span>
                    </b>
                  </li>
                </ol>
                <ol style={{ marginTop: "0in" }} start={6} type={1}>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        The ResearchON App and/or the Services are not intended
                        for use of minors. ResearchON does not knowingly collect
                        any Personal Information from any minor or market to or
                        solicit information from any minor. If ResearchON
                        becomes aware that a person submitting Personal
                        Information is a minor, ResearchON shall delete such
                        User’s Account and any related information immediately.
                        If any persons believe that they may have any
                        information from or about a minor using the ResearchON
                        App and/or Services, please contact ResearchON at&nbsp;
                        <a href="mailto:team@researchon.ai">
                          team@researchon.ai
                        </a>
                        .
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON strongly encourages parents and guardians to
                        supervise the online activities of their minor children
                        and consider using parental control tools available from
                        online services and software manufacturers to help
                        provide a child-friendly online environment. These tools
                        also can prevent minors from disclosing their name,
                        address, and other personally identifiable information
                        online without parental permission.
                      </span>
                    </li>
                  </ol>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    <br />
                    <br />
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={7} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">CONSENT TO THIS PRIVACY POLICY</span>
                    </b>
                  </li>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    You acknowledge that this Privacy Policy is a part of the
                    ResearchON Terms of Use of the ResearchON App and the other
                    Services, and you unconditionally agree that becoming a User
                    of the ResearchON App and its Services signifies your (i)
                    assent to this Privacy Policy; and (ii) consent to
                    ResearchON using, collecting, processing and/or disclosing
                    your Personal Information in the manner and for the purposes
                    set out in this Privacy Policy. Your visit to the ResearchON
                    App and use of the Services is subject to this Privacy
                    Policy and the ResearchON Terms of Use.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    <br />
                    <br />
                  </span>
                </p>
                <ol style={{ marginTop: "0in" }} start={8} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">CHANGE TO THIS PRIVACY POLICY</span>
                    </b>
                  </li>
                </ol>
                <ol style={{ marginTop: "0in" }} start={8} type={1}>
                  <ol style={{ marginTop: "0in" }} start={1} type={1}>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        ResearchON may update this Privacy Policy at any time,
                        with or without advance notice. In the event there are
                        significant changes to this Privacy Policy, ResearchON
                        will display a notice on the ResearchON App or send
                        Users an email, as provided for above, so that you may
                        review the changed terms prior to continuing to use the
                        Services. As always, if you object to any of the changes
                        to our terms, and you no longer wish to use the
                        Services, you may write to us&nbsp;
                        <a href="mailto:team@researchon.ai">
                          team@researchon.ai
                        </a>
                        &nbsp;to deactivate your account. Unless stated
                        otherwise, ResearchONs’ current Privacy Policy applies
                        to all information that ResearchON has about you and
                        your account.
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      style={{
                        color: "#333333",
                        marginTop: "3.75pt",
                        textAlign: "justify",
                        lineHeight: "115%",
                        background: "white",
                      }}
                    >
                      <span lang="EN-IN">
                        If a User uses the Services or accesses the ResearchON
                        App after a notice of changes has been sent to such User
                        or published on the ResearchON App, such User hereby
                        provides his/her/its consent to the changed terms.
                        <br />
                        <br />
                      </span>
                    </li>
                  </ol>
                </ol>
                <ol style={{ marginTop: "0in" }} start={9} type={1}>
                  <li
                    className="MsoNormal"
                    style={{
                      color: "#333333",
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "115%",
                      background: "white",
                    }}
                  >
                    <b>
                      <span lang="EN-IN">CONTACT US</span>
                    </b>
                  </li>
                </ol>
                <p
                  className="MsoNormal"
                  style={{
                    marginTop: "3.75pt",
                    marginRight: "0in",
                    marginBottom: "18.75pt",
                    marginLeft: ".5in",
                    textAlign: "justify",
                    lineHeight: "115%",
                    background: "white",
                  }}
                >
                  <span
                    lang="EN-IN"
                    style={{
                      color: "#333333",
                    }}
                  >
                    If you have any grievance with respect to our use of your
                    information, you may communicate such grievance at the
                    co-ordinates provided below. Please write to grievance
                    officer at&nbsp;
                    <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                    &nbsp;and our officer will try to resolve your issue.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{ textAlign: "justify", lineHeight: "115%" }}
                >
                  <span lang="EN-IN">&nbsp;</span>
                </p>
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h4>Unleash Your Research Potential with ResearchON</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <img
                    src="../assets/img/logo.png"
                    alt=""
                    className="img-fluid"
                    style={{ width: 160 }}
                  />
                  <p>
                    HSR Layout <br />
                    Bangalore - 560102
                    <br />
                    India <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+91 89717 02797"> +91 89717 02797 </a>
                    <br />
                    <strong>Email:</strong>{" "}
                    <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                    <br />
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/terms-and-conditions/">Terms of service</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/privacy-policy/">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="https://myportal.researchon.ai" target="_blank">
                        MyPortal
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/download-app/">Download App</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/data-security/">Data Secuirty</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Social Networks</h4>
                  <p>Please follow us on our social media platforms</p>
                  <div className="social-links mt-3">
                    <a href="#" className="instagram">
                      <i className="bx bxl-instagram" />
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
            <div className="copyright">
              COGNIDOC HEALTHTECH PRIVATE LIMITED. © 2024. All Rights Reserved
            </div>
            <div className="credits"></div>
          </div>
        </footer>
        {/* End Footer */}
      </>
    </div>
  );
};

export default PrivacyPolicy;
