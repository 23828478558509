import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";

import AOS from "aos";
import "../files/aos.css";

import Lottie from "react-lottie";
import animationData from "../files/record-button.json";

import { Helmet } from "react-helmet";

const Home = () => {
  const [popup, setPopup] = useState(1);

  const updatePopup = (num) => {
    setPopup(popup === num ? 0 : num);
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount/update

    const swiper = new Swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    });

    // Store swiper instance in window object
    window.swiper = swiper;

    return () => {
      swiper.destroy(true, true);
    };
  }, []); // Run only once after component mounts

  const handlePrevClick = () => {
    if (window.swiper) {
      window.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (window.swiper) {
      window.swiper.slideNext();
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const texts = ["research", "audit", "thesis", "case series", "survey"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    // Reset charIndex and clear currentText when changing to the next text
    const totalDisplayTime = texts[currentIndex].length * 150 + 500; // Adjust the time as needed

    const changeTextTimeout = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setCurrentText(""); // Clear the text for the next round
      setCharIndex(0); // Reset character index
    }, totalDisplayTime);

    return () => clearTimeout(changeTextTimeout);
  }, [currentIndex, texts]);

  useEffect(() => {
    if (charIndex < texts[currentIndex].length) {
      const typeCharacter = setTimeout(() => {
        setCurrentText(texts[currentIndex].substring(0, charIndex + 1));
        setCharIndex(charIndex + 1);
      }, 150); // Typing speed
      return () => clearTimeout(typeCharacter);
    }
  }, [charIndex, currentIndex, texts]);

  function setMetaTags() {
    const metaTags = [
      { name: "title", content: "ResearchON" },
      {
        name: "description",
        content:
          "From idea to publication, with analytics and data collection, we've got it all covered in one platform",
      },
      {
        name: "keywords",
        content:
          "ResearchON, Cognidoc Healthtech Private Limited, Dockare, Orbit, Healthcare",
      },
      { property: "og:title", content: "ResearchON" },
      {
        property: "og:description",
        content:
          "From idea to publication, with analytics and data collection, we've got it all covered in one platform",
      },
      {
        property: "og:image",
        content: "https://www.researchon.ai/assets/img/researchon-logo.png",
      },
      { property: "og:url", content: "https://www.researchon.ai" },
      // Add more meta tags as needed
    ];

    // First, remove any existing meta tags to avoid duplicates
    document
      .querySelectorAll(
        'meta[name="title"], meta[name="description"], meta[name="keywords"], meta[property^="og:"]'
      )
      .forEach((meta) => meta.remove());

    // Next, add the new meta tags
    metaTags.forEach((tag) => {
      const metaTag = document.createElement("meta");
      Object.keys(tag).forEach((key) => {
        metaTag.setAttribute(key, tag[key]);
      });
      document.head.appendChild(metaTag);
    });
  }

  useEffect(() => {
    setMetaTags();
  }, []);
  return (
    <>
      <div>
        <Helmet>
          <title>ResearchON</title>
          <meta name="title" content="ResearchON" />
          <meta
            name="keywords"
            content="ResearchON, Cognidoc Healthtech Private Limited, Dockare, Orbit, Healthcare"
          />
          <meta
            name="description"
            content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
          />
          <meta property="og:title" content="ResearchON" />
          <meta property="og:url" content="https://www.researchon.ai" />
          <meta
            property="og:description"
            content="From idea to publication, with analytics and data collection, we've got it all covered in one platform"
          />
          <meta
            property="og:image"
            content="https://www.researchon.ai/assets/img/researchon-logo.png"
          />
          <meta property="og:type" content="Healthtech" />
        </Helmet>
      </div>
      <div>
        <header className="fixed-top " id="header">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="logo">
              <h1>
                <a toa="">
                  <img alt="" className="img-fluid" src="assets/img/logo.png" />
                </a>
              </h1>
            </div>

            <nav className="navbar" id="navbar">
              <ul>
                <li>
                  <a
                    className="getstarted scrollto"
                    href="https://myportal.researchon.ai"
                    target="_blank"
                  >
                    Access MyPortal
                  </a>
                </li>
              </ul>
              <a
                className="getstarted show-app"
                href="https://myportal.researchon.ai"
                target="_blank"
              >
                MyPortal
              </a>
            </nav>
          </div>
        </header>
        <main
          id="main"
          style={{
            marginTop: "70px",
          }}
        >
          <section className="inner-page main-back-cover" id="hero">
            <div className="container">
              <div className="row">
                <div
                  align="center"
                  className="col-lg-6 col-md-6 col-sm-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
                ></div>
                <div className="col-md-6 col-sm-6">
                  <div className="border-box-app">
                    <div className="stop-blink top-left-border-app" />
                    <div className="stop-blink bottom-right-border-app" />
                  </div>
                  <h1 className="main-title-black">
                    Where every idea <br /> evolves into a <br />{" "}
                    <span className="text-orange typing-container">
                      breakthrough{" "}
                      <span className="typing-text">{currentText}</span>
                    </span>
                  </h1>
                  <div className="download-apps">
                    <img
                      alt=""
                      className="img-fluid download-logo"
                      src="assets/img/logo.png"
                    />
                    <h3 className="download-title">Download Now!</h3>
                    <a
                      className="download-btn"
                      href="https://play.google.com/store/apps/details?id=com.dockare.scrubs"
                      target="_blank"
                    >
                      <i className="bx bxl-play-store" /> Google Play
                    </a>
                    <a
                      className="download-btn"
                      href="https://apps.apple.com/in/app/id1662056245"
                      target="_blank"
                    >
                      <i className="bx bxl-apple" /> App Store
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="gallery hide-app"
            id="gallery"
            onClick={() => {
              updatePopup(0);
            }}
          >
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">
                  Where busy minds <span className="text-orange">expedite</span>{" "}
                  their research journey
                </h1>
              </div>
            </div>
            <div
              className="container-fluid sub-back-cover-1"
              data-aos="fade-up"
            >
              <label
                className="show-dot-popup dot-1"
                data-id="dot-place-1"
                style={{
                  width: "40px",
                  display: popup === 0 ? "inline-block" : "inline-block",
                }}
                onClick={(e) => {
                  updatePopup(1);
                  e.stopPropagation();
                }}
              >
                <Lottie options={defaultOptions} height={40} width={40} />
              </label>
              <label
                className="show-dot-popup dot-2"
                data-id="dot-place-2"
                style={{
                  width: "40px",
                  display: popup === 0 ? "inline-block" : "inline-block",
                }}
                onClick={(e) => {
                  updatePopup(2);
                  e.stopPropagation();
                }}
              >
                <Lottie options={defaultOptions} height={40} width={40} />
              </label>
              <label
                className="show-dot-popup dot-3"
                data-id="dot-place-3"
                id="lottie-animation-3"
                style={{
                  width: "40px",
                  display: popup === 0 ? "inline-block" : "inline-block",
                }}
                onClick={(e) => {
                  updatePopup(3);
                  e.stopPropagation();
                }}
              >
                <Lottie options={defaultOptions} height={40} width={40} />
              </label>
              <label
                className="show-dot-popup dot-4"
                data-id="dot-place-4"
                id="lottie-animation-4"
                style={{
                  width: "40px",
                  display: popup === 0 ? "inline-block" : "inline-block",
                }}
                onClick={(e) => {
                  updatePopup(4);
                  e.stopPropagation();
                }}
              >
                <Lottie options={defaultOptions} height={40} width={40} />
              </label>
              <label
                className="show-dot-popup dot-5"
                data-id="dot-place-5"
                id="lottie-animation-5"
                style={{
                  width: "40px",
                  display: popup === 0 ? "inline-block" : "inline-block",
                }}
                onClick={(e) => {
                  updatePopup(5);
                  e.stopPropagation();
                }}
              >
                <Lottie options={defaultOptions} height={40} width={40} />
              </label>
              <label
                className="show-dot-popup dot-6"
                data-id="dot-place-6"
                id="lottie-animation-6"
                style={{
                  width: "40px",
                  display: popup === 0 ? "inline-block" : "inline-block",
                }}
                onClick={(e) => {
                  updatePopup(6);
                  e.stopPropagation();
                }}
              >
                <Lottie options={defaultOptions} height={40} width={40} />
              </label>

              <div
                className="dot-popup dot-place-1"
                style={{ display: popup === 1 ? "block" : "none" }}
              >
                <div className="popup-scroll">
                  <table className="custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>John Newman</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Ward nurse</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Type of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Audit 2, Research 1, Case series 1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Area of interest</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>
                            Patient safety, <br />
                            Nurse workflow management
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Studies on</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <img
                            alt=""
                            className="img-fluid"
                            src="assets/img/logo.png"
                            style={{
                              width: "100px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="dot-popup dot-place-2"
                style={{ display: popup === 2 ? "block" : "none" }}
              >
                <div className="popup-scroll">
                  <table className="custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Rani DSouza</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Surgical nurse</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>3</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Type of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Audit 2, Research 1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Area of interest</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Drug safety, Nurse welfare</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Studies on</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <img
                            alt=""
                            className="img-fluid"
                            src="assets/img/logo.png"
                            style={{
                              width: "100px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="dot-popup dot-place-3"
                style={{ display: popup === 3 ? "block" : "none" }}
              >
                <div className="popup-scroll">
                  <table className="custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Rebacca Scott</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Medical nurse</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>4</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Type of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Audit 3, Research 1</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Area of interest</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>
                            Mortality and morbidity meetings, <br />
                            Palliative care
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Studies on</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <img
                            alt=""
                            className="img-fluid"
                            src="assets/img/logo.png"
                            style={{
                              width: "100px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="dot-popup dot-place-4"
                style={{ display: popup === 4 ? "block" : "none" }}
              >
                <div className="popup-scroll">
                  <table className="custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Clara Barton</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Patient liaison</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>7</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Type of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Audit 5, Research 2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Area of interest</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>
                            Patient welfare, IP and <br />
                            OP workflow management
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Studies on</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <img
                            alt=""
                            className="img-fluid"
                            src="assets/img/logo.png"
                            style={{
                              width: "100px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="dot-popup dot-place-5"
                style={{ display: popup === 5 ? "block" : "none" }}
              >
                <div className="popup-scroll">
                  <table className="custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Amit Gupta</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>PGY3 Resident, Paediatrics</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>8</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Type of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>
                            Audits 6, Research 2, <br />
                            Case series 2
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Area of interest</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>
                            Paediatric intensive care, <br />
                            Advance simulations, <br />
                            Human factors
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Studies on</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <img
                            alt=""
                            className="img-fluid"
                            src="assets/img/logo.png"
                            style={{
                              width: "100px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="dot-popup dot-place-6"
                style={{ display: popup === 6 ? "block" : "none" }}
              >
                <div className="popup-scroll">
                  <table className="custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Emily Barnes</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Role</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Lead drug safety manager</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>18</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Type of studies</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>Audits 14, Research 2, Survey 2</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Area of interest</strong>
                        </td>
                        <td>:</td>
                        <td className="typing-animation">
                          <span>
                            Look alike sound alike drugs, <br />
                            Drug movement in hospital, <br />
                            Clinical trial coordinator{" "}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Studies on</strong>
                        </td>
                        <td>:</td>
                        <td>
                          <img
                            alt=""
                            className="img-fluid"
                            src="assets/img/logo.png"
                            style={{
                              width: "100px",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
          <section className="gallery padding-top" id="gallery">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">
                  <span className="text-orange">Say goodbye</span> to the
                  constraints of one research project every few years
                </h1>
              </div>
            </div>
            <div className="container-fluid" data-aos="fade-up">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-5 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1 padding-left"
                    data-aos="fade-up"
                  >
                    <h4 className="sub-title-black-3">
                      With ResearchON, you can delve into multiple projects each
                      year, making research a dynamic part of your academic and
                      professional life.
                    </h4>
                    <div className="download-apps-2">
                      <img
                        alt=""
                        className="img-fluid download-logo-2"
                        src="assets/img/logo.png"
                      />
                      <h3 className="download-title-2">Download Now!</h3>
                      <a
                        className="download-btn"
                        href="https://play.google.com/store/apps/details?id=com.dockare.scrubs"
                        target="_blank"
                      >
                        <i className="bx bxl-play-store" /> Google Play
                      </a>
                      <a
                        className="download-btn"
                        href="https://apps.apple.com/in/app/id1662056245"
                        target="_blank"
                      >
                        <i className="bx bxl-apple" /> App Store
                      </a>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <img
                      alt=""
                      className=""
                      src="assets/img/background-2.png"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gallery hide-app" id="features">
            <div className="container feature-background" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">Our Features</h1>
              </div>
              <div>
                <ul
                  className="nav nav-pills nav-justified mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item margin-y" role="presentation">
                    <button
                      aria-controls="pills-home"
                      aria-selected="true"
                      className="nav-link tab-list active"
                      data-bs-target="#pills-home"
                      data-bs-toggle="pill"
                      id="pills-home-tab"
                      role="tab"
                      type="button"
                    >
                      Optimize idea generation
                    </button>
                  </li>
                  <li className="nav-item margin-y" role="presentation">
                    <button
                      aria-controls="pills-profile"
                      aria-selected="false"
                      className="nav-link tab-list"
                      data-bs-target="#pills-profile"
                      data-bs-toggle="pill"
                      id="pills-profile-tab"
                      role="tab"
                      type="button"
                    >
                      Build eCRF <br />
                      in seconds
                    </button>
                  </li>
                  <li className="nav-item margin-y" role="presentation">
                    <button
                      aria-controls="pills-contact"
                      aria-selected="false"
                      className="nav-link tab-list"
                      data-bs-target="#pills-contact"
                      data-bs-toggle="pill"
                      id="pills-contact-tab"
                      role="tab"
                      type="button"
                    >
                      Pilot study before going live
                    </button>
                  </li>
                  <li className="nav-item margin-y" role="presentation">
                    <button
                      aria-controls="pills-test"
                      aria-selected="false"
                      className="nav-link tab-list"
                      data-bs-target="#pills-test"
                      data-bs-toggle="pill"
                      id="pills-test-tab"
                      role="tab"
                      type="button"
                    >
                      Mobile-based data collection
                    </button>
                  </li>
                  <li className="nav-item margin-y" role="presentation">
                    <button
                      aria-controls="pills-collect"
                      aria-selected="false"
                      className="nav-link tab-list"
                      data-bs-target="#pills-collect"
                      data-bs-toggle="pill"
                      id="pills-collect-tab"
                      role="tab"
                      type="button"
                    >
                      Real-time <br /> data/insights{" "}
                    </button>
                  </li>
                  <li className="nav-item margin-y" role="presentation">
                    <button
                      aria-controls="pills-review"
                      aria-selected="false"
                      className="nav-link tab-list"
                      data-bs-target="#pills-review"
                      data-bs-toggle="pill"
                      id="pills-review-tab"
                      role="tab"
                      type="button"
                    >
                      Statistical analytics and visualization
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    aria-labelledby="pills-home-tab"
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-md-12 padding-x40">
                        <h4 className="list-title">
                          From concept to execution, streamline your research
                          journey with our intuitive ideation platform.{" "}
                        </h4>
                      </div>
                      <div className="col-md-6 p-20">
                        <ul className="list-points">
                          <li>
                            Get AI-generated protocols aligned with your
                            research goals instantly.
                          </li>
                          <li>
                            Your AI-customized questionnaire is created in
                            seconds using only your study title.
                          </li>
                          <li>
                            Research Diary helps you effortlessly organize all
                            your research notes with prebuilt study headers to
                            accelerate your research.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="feature-img-2 mt-40">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/optimize-idea-generation.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="pills-profile-tab"
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-md-12 padding-x40">
                        <h4 className="list-title">
                          Craft custom study forms quickly and easily to
                          streamline your data collection process.{" "}
                        </h4>
                      </div>
                      <div className="col-md-6 p-20">
                        <ul className="list-points">
                          <li>
                            Use easy and intuitive drag-and-drop field options.
                          </li>
                          <li>
                            Tailor study with advanced branching logics and
                            submission limits.{" "}
                          </li>
                          <li>
                            Safeguard participant sensitive information by
                            hiding confidential data.
                          </li>
                          <li>
                            Conduct longitudinal studies with multiple forms.
                          </li>
                          <li>
                            Preview forms to assess user experience and
                            function.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="feature-img-2 mt-40">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/build-ecrf.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="pills-contact-tab"
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-md-12 padding-x40">
                        <h4 className="list-title">
                          Ensure research success with a pilot study before
                          officially launching your project.{" "}
                        </h4>
                      </div>
                      <div className="col-md-6 p-20">
                        <ul className="list-points">
                          <li>
                            Pilot study with 25 participants to refine and
                            validate your study.
                          </li>
                          <li>
                            Validate collected data and analytics prior to going
                            live.
                          </li>
                          <li>
                            Invite project managers, data collectors and other
                            team members with complete role-based access.
                          </li>
                          <li>
                            Piloting maintains data integrity and prevents
                            modifications after going live with your study.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="feature-img-2 mt-40">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/pilot-study-before-going-live.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="pills-test-tab"
                    className="tab-pane fade"
                    id="pills-test"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-md-12 padding-x40">
                        <h4 className="list-title">
                          Collect data conveniently anytime, anywhere, using our
                          user-friendly mobile platform.{" "}
                        </h4>
                      </div>
                      <div className="col-md-6 p-20">
                        <ul className="list-points">
                          <li>
                            Gather participant data both online and offline.
                          </li>
                          <li>Upload all your offline data in one click.</li>
                          <li>
                            Communicate and collaborate with team members
                            through first of its kind participant channels.{" "}
                          </li>
                          <li>
                            Trace the form submissions, for enhanced data
                            accountability.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="feature-img-2 mt-40">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/mobile-based-data-collection.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="pills-collect-tab"
                    className="tab-pane fade"
                    id="pills-collect"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-md-12 padding-x40">
                        <h4 className="list-title">
                          Access real-time insights for informed
                          decision-making, simplifying your research process.{" "}
                        </h4>
                      </div>
                      <div className="col-md-6 p-20">
                        <ul className="list-points">
                          <li>
                            Review data with participant IDs tagged for easy
                            identification.
                          </li>
                          <li>
                            Pioneering real-time master chart generation.{" "}
                          </li>
                          <li>
                            Edit master chart for efficient data cleaning and
                            trailing.
                          </li>
                          <li>
                            Export form data and master chart to common file
                            formats.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="feature-img-2 mt-40">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/realtime-data-insights.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="pills-review-tab"
                    className="tab-pane fade"
                    id="pills-review"
                    role="tabpanel"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="col-md-12 padding-x40">
                        <h4 className="list-title">
                          Easily interpret data with intuitive analytics tools,
                          making analysis simpler and more efficient.{" "}
                        </h4>
                      </div>
                      <div className="col-md-6 p-20">
                        <ul className="list-points">
                          <li>Utilize master chart for data analytics.</li>
                          <li>
                            Drag-and-drop single/ multiple variables to perform
                            analytics.
                          </li>
                          <li>
                            Instantly generate intuitive graphs for simplified
                            analysis.
                          </li>
                          <li>
                            Dive deeper into data with advanced analytical
                            tools.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <div className="feature-img-2 mt-40">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/statistical-analytics.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="gallery show-app padding-top-bottom"
            id="features"
          >
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">Our Features</h1>
              </div>
              <div
                className="carousel slide feature-background"
                id="carouselExampleIndicators"
              >
                <div className="carousel-indicators">
                  <button
                    aria-current="true"
                    aria-label="Slide 1"
                    className="active"
                    data-bs-slide-to="0"
                    data-bs-target="#carouselExampleIndicators"
                    type="button"
                  />
                  <button
                    aria-label="Slide 2"
                    data-bs-slide-to="1"
                    data-bs-target="#carouselExampleIndicators"
                    type="button"
                  />
                  <button
                    aria-label="Slide 3"
                    data-bs-slide-to="2"
                    data-bs-target="#carouselExampleIndicators"
                    type="button"
                  />
                  <button
                    aria-label="Slide 4"
                    data-bs-slide-to="3"
                    data-bs-target="#carouselExampleIndicators"
                    type="button"
                  />
                  <button
                    aria-label="Slide 5"
                    data-bs-slide-to="4"
                    data-bs-target="#carouselExampleIndicators"
                    type="button"
                  />
                  <button
                    aria-label="Slide 6"
                    data-bs-slide-to="5"
                    data-bs-target="#carouselExampleIndicators"
                    type="button"
                  />
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="text-center">
                      <h4 className="list-title">Optimize Idea Generation </h4>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="feature-img-2">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/optimize-idea-generation.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 p-20">
                        <ul className="list-points">
                          <li>
                            Get AI-generated protocols aligned with your
                            research goals instantly.
                          </li>
                          <li>
                            Your AI-customized questionnaire is created in
                            seconds using only your study title.
                          </li>
                          <li>
                            Research Diary helps you effortlessly organize all
                            your research notes with prebuilt study headers to
                            accelerate your research.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="text-center">
                        <h4 className="list-title">Build eCRF in seconds </h4>
                      </div>
                      <div className="col-sm-12">
                        <div className="feature-img-2">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/build-ecrf.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 p-20">
                        <ul className="list-points">
                          <li>
                            Use easy and intuitive drag-and-drop field options.
                          </li>
                          <li>
                            Tailor study with advanced branching logics and
                            submission limits.{" "}
                          </li>
                          <li>
                            Safeguard participant sensitive information by
                            hiding confidential data.
                          </li>
                          <li>
                            Conduct longitudinal studies with multiple forms.
                          </li>
                          <li>
                            Preview forms to assess user experience and
                            function.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="text-center">
                        <h4 className="list-title">
                          Pilot study before going live   
                        </h4>
                      </div>
                      <div className="col-sm-12">
                        <div className="feature-img-2">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/pilot-study-before-going-live.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 p-20">
                        <ul className="list-points">
                          <li>
                            Pilot study with 25 participants to refine and
                            validate your study.
                          </li>
                          <li>
                            Validate collected data and analytics prior to going
                            live.
                          </li>
                          <li>
                            Invite project managers, data collectors and other
                            team members with complete role-based access.
                          </li>
                          <li>
                            Piloting maintains data integrity and prevents
                            modifications after going live with your study.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="text-center">
                        <h4 className="list-title">
                          Mobile based data collection 
                        </h4>
                      </div>
                      <div className="col-sm-12">
                        <div className="feature-img-2">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/mobile-based-data-collection.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 p-20">
                        <ul className="list-points">
                          <li>
                            Gather participant data both online and offline.
                          </li>
                          <li>Upload all your offline data in one click.</li>
                          <li>
                            Communicate and collaborate with team members
                            through first of its kind participant channels.{" "}
                          </li>
                          <li>
                            Trace the form submissions, for enhanced data
                            accountability.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="text-center">
                        <h4 className="list-title">Realtime Data/Insights </h4>
                      </div>
                      <div className="col-sm-12">
                        <div className="feature-img-2">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/realtime-data-insights.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 p-20">
                        <ul className="list-points">
                          <li>
                            Review data with participant IDs tagged for easy
                            identification.
                          </li>
                          <li>
                            Pioneering real-time master chart generation.{" "}
                          </li>
                          <li>
                            Edit master chart for efficient data cleaning and
                            trailing.
                          </li>
                          <li>
                            Export form data and master chart to common file
                            formats.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="text-center">
                        <h4 className="list-title">
                          Statistical analytics and visualization
                        </h4>
                      </div>
                      <div className="col-sm-12">
                        <div className="feature-img-2">
                          <img
                            alt="illustration"
                            className="img-fluid"
                            src="assets/img/features/statistical-analytics.png"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 p-20">
                        <ul className="list-points">
                          <li>Utilize master chart for data analytics.</li>
                          <li>
                            Drag-and-drop single/ multiple variables to perform
                            analytics.
                          </li>
                          <li>
                            Instantly generate intuitive graphs for simplified
                            analysis.
                          </li>
                          <li>
                            Dive deeper into data with advanced analytical
                            tools.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  data-bs-slide="prev"
                  data-bs-target="#carouselExampleIndicators"
                  type="button"
                >
                  <span
                    aria-hidden="true"
                    className="carousel-control-prev-icon"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  data-bs-slide="next"
                  data-bs-target="#carouselExampleIndicators"
                  type="button"
                >
                  <span
                    aria-hidden="true"
                    className="carousel-control-next-icon"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </section>
          <section className="gallery" id="works">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">
                  How does{" "}
                  <img
                    alt="logo"
                    className="img-fluid"
                    src="assets/img/logo.png"
                    style={{
                      height: "40px",
                      marginTop: "-4px",
                    }}
                  />{" "}
                  work?
                </h1>
              </div>
            </div>
            <div className="container iframe-conatainer" data-aos="fade-up">
              <iframe
                src="https://app.supademo.com/embed/cltk22djr0guycd1jr8oy8opt"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          </section>
          <section className="testimonials section-bg" id="testimonials">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">Testimonials</h1>
              </div>
              <div
                className="testimonials-slider swiper"
                data-aos="fade-up"
                data-aos-delay="{100}"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        alt=""
                        className="testimonial-img"
                        src="assets/img/testimonials/Dr.Karen-Dsa.png"
                      />
                      <h3>Dr. Karen Maria D'SA</h3>
                      <h4>Assistant Professor, Orthopedic department</h4>
                      <p className="scrollable">
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        I am an Orthopaedic surgeon and managing, assembling and
                        effectively storing research data in a time bound
                        manner, has to be my shortcoming.This app is perfect for
                        feeding into my O.C.D ( Obsessive compusive disorder)
                        nature, pertaining to research .It helps organise and
                        assemble multiple studies through the clear and
                        interactive dashboards .It stimulates thought provoking
                        research questions and queries. Overall, dare I say it
                        is the best tool for a busy surgeon, “Digital paper
                        whilst on the run”, without having the mundane and
                        tedious task of sieving through loads of data . As a
                        primary investigator, multiple projects can be juggled
                        into one file, whilst simultaneouly maintaining the
                        quality of research published -it gives due diligence to
                        each project. Having said that, it does have some
                        glitches that needs patience to motor through like any
                        other app but overall it definitely is a convenient
                        crutch to have as a surgeon .
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        alt=""
                        className="testimonial-img"
                        src="assets/img/testimonials/dr.sanjeev.png"
                      />
                      <h3>Dr. Sanjeev Badiger</h3>
                      <h4>HOD, Department of Community Medicine</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        "The interactive dashboards in ResearchON have been a
                        revelation. As the Chief Admin, I now have a complete
                        overview of all our ongoing projects and their real-time
                        status. This allows me to make informed decisions and
                        ensure everything stays on track." Thanks for providing
                        us a good research tool
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        alt=""
                        className="testimonial-img"
                        src="assets/img/testimonials/Dr.Lillian-Tulley.png"
                      />
                      <h3>Dr. Lillian Tuley</h3>
                      <h4>Attending Consultant</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        As a pediatrician, manually conducting research studies
                        was incredibly time-consuming and demanding.
                        ResearchON's digital platform and complete ecosystem
                        streamlined the process, allowing me to conduct studies,
                        audits, and case series efficiently.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        alt=""
                        className="testimonial-img"
                        src="assets/img/testimonials/miheer.jpg"
                      />
                      <h3>Dr. Miheer Patankar </h3>
                      <h4>
                        Owner and Head Dentist <br />
                        at Patankar's Dental Clinic
                      </h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Organizing and collating radiographic images and other
                        dental data from multiple patients was a complex task.
                        ResearchON's enhanced data management features have
                        simplified this process, allowing me to efficiently
                        manage data for my dental research projects.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        alt=""
                        className="testimonial-img"
                        src="assets/img/testimonials/dr.mahima-shetty.jpg"
                      />
                      <h3>Dr. Mahima Shetty </h3>
                      <h4>Consultant Pediatrician</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        ResearchON's user-friendly platform was a perfect fit
                        for my busy schedule. The online tools allowed me to
                        design my study quickly and efficiently, from
                        formulating the research questions and adding
                        participants to analysing data and generating reports.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        alt=""
                        className="testimonial-img"
                        src="assets/img/testimonials/Channamma-Hasbi.png"
                      />
                      <h3>Dr. Channamma Hasbi</h3>
                      <h4>General Physician</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        ResearchON is my all-in-one research companion, guiding
                        me from planning my study to publishing it. Their
                        platform digitized my data and streamlined the entire
                        process, saving me time and boosting my research
                        efficiency.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                      </p>
                    </div>
                  </div>
                </div>

                {/* start pagination */}

                <div className="swiper-pagination">
                  <img
                    src="assets/img/prev.png"
                    onClick={handlePrevClick}
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <img
                    src="assets/img/next.png"
                    onClick={handleNextClick}
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      marginLeft: "10px",
                    }}
                  />
                </div>

                {/* end pagination */}
              </div>
            </div>
          </section>
          <section className="gallery" id="partners">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">Our Institute Partners</h1>
              </div>
            </div>
            <div className="container" data-aos="fade-up">
              <div className="d-flex align-items-center justify-content-center flex-direction">
                <div align="center" className="partner-block">
                  <img
                    alt="logo"
                    className="img-fluid partner-logo"
                    src="assets/img/logos/yen.png"
                  />
                  <h3 className="partner-title">
                    Yenepoya Medical College
                    <br />
                    Mangalore
                  </h3>
                </div>
                <div align="center" className="partner-block">
                  <img
                    alt="logo"
                    className="img-fluid partner-logo"
                    src="assets/img/logos/beld.jpg"
                  />
                  <h3 className="partner-title">
                    BLDE Medical College <br />
                    Bijapur
                  </h3>
                </div>
                <div align="center" className="partner-block">
                  <img
                    alt="logo"
                    className="img-fluid partner-logo"
                    src="assets/img/logos/ajs.jpg"
                  />
                  <h3 className="partner-title">
                    A. J. Institute of Medical Sciences
                    <br />
                    Mangalore
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="faq section-bg" id="faq">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title">
                <h1 className="sub-title-black">Frequently Asked Questions</h1>
              </div>
              <div className="accordion-list">
                <ul>
                  <li className="aos-init aos-animate" data-aos="fade-up">
                    <a
                      className="collapse"
                      data-bs-target="#accordion-list-1"
                      data-bs-toggle="collapse"
                    >
                      1. Can students use ResearchON for academic projects, or
                      is it primarily for professional research?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse show"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-1"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        Yes, ResearchON welcomes students, professionals, and
                        anyone conducting research to utilize the platform. Our
                        platform caters to the needs of both professional and
                        academic research needs.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-3"
                      data-bs-toggle="collapse"
                    >
                      2. What special discounts or packages does ResearchON
                      offer for students?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-3"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        ResearchON offers highly discounted pre-built packages
                        specially curated for students. These packages are
                        designed to provide students with access to essential
                        research tools and resources at affordable rates.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-4"
                      data-bs-toggle="collapse"
                    >
                      3. Are there any discounts available for educational
                      institutions or non-profit organizations?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-4"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        Yes, we offer discounts for educational institutions and
                        non-profit organizations. Please contact our support at
                        <a
                          href="mailto:team@researchon.ai"
                          style={{ display: "inline" }}
                        >
                          team@researchon.ai
                        </a>
                        for more information on eligibility and discounts.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-5"
                      data-bs-toggle="collapse"
                    >
                      4. Can I upgrade my subscription plan at any time?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-5"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        Yes, you can upgrade your subscription plan at any time.
                        Simply log in to your account and navigate the study
                        settings, click on payments and invoices to make
                        changes.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-6"
                      data-bs-toggle="collapse"
                    >
                      5. Is there a limit to the number of projects/ studies I
                      can create on ResearchON?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-6"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        No, there is no limit to the number of projects/studies
                        you can create on ResearchON.
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-7"
                      data-bs-toggle="collapse"
                    >
                      6. How do I cancel my subscription on ResearchON?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-7"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        To cancel your subscription on ResearchON, please write
                        to us at{" "}
                        <a
                          href="mailto:team@researchon.ai"
                          style={{ display: "inline" }}
                        >
                          {" "}
                          team@researchon.ai
                        </a>
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-8"
                      data-bs-toggle="collapse"
                    >
                      7. How can I get technical support or assistance if I
                      encounter any issues while using ResearchON?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-8"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        If you have any technical issues or need assistance with
                        ResearchON, reach out to our support team via email (
                        <a
                          href="mailto:team@researchon.ai"
                          style={{ display: "inline" }}
                        >
                          team@researchon.ai
                        </a>
                        ) or WhatsApp (
                        <a
                          href="https://wa.me/+918971702797"
                          style={{ display: "inline" }}
                        >
                          +918971702797
                        </a>
                        ). We're here to help!
                      </p>
                    </div>
                  </li>
                  <li
                    className="aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <a
                      className="collapsed"
                      data-bs-target="#accordion-list-10"
                      data-bs-toggle="collapse"
                    >
                      8. Does ResearchON offer any training or tutorials for new
                      users?
                      <i className="bx bx-chevron-down icon-show" />
                      <i className="bx bx-chevron-up icon-close" />
                    </a>
                    <div
                      className="collapse"
                      data-bs-parent=".accordion-list"
                      id="accordion-list-10"
                    >
                      <p style={{ color: "#2F2F2F", fontFamily: "Inter" }}>
                        Yes, ResearchON provides training materials and
                        tutorials to help new users get started with the
                        platform. You can access these resources through the
                        MyPortal or app to learn how to navigate and use
                        ResearchON effectively.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
        <footer id="footer">
          <div className="footer-newsletter">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <h4>Unleash Your Research Potential with ResearchON</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <img
                    alt=""
                    className="img-fluid"
                    src="assets/img/logo.png"
                    style={{
                      width: "160px",
                    }}
                  />
                  <p>
                    HSR Layout <br />
                    Bangalore - 560102
                    <br />
                    India <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+91 89717 02797"> +91 89717 02797 </a>
                    <br />
                    <strong>Email:</strong>{" "}
                    <a href="mailto:team@researchon.ai">team@researchon.ai</a>
                    <br />
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />
                      <Link to="/terms-and-conditions/">Terms of service</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />
                      <Link to="/privacy-policy/">Privacy policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />
                      <a href="https://myportal.researchon.ai" target="_blank">
                        MyPortal
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />
                      <Link to="/download-app/">Download App</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <Link to="/data-security/">Data Secuirty</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Social Networks</h4>
                  <p>Please follow us on our social media platforms</p>
                  <div className="social-links mt-3">
                    <a className="instagram" href="#">
                      <i className="bx bxl-instagram" />
                    </a>
                    <a className="linkedin" href="#">
                      <i className="bx bxl-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
            <div className="copyright">
              COGNIDOC HEALTHTECH PRIVATE LIMITED. © 2024. All Rights Reserved
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
